import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import { IQueryParams } from "queries";


export interface TypedDriver {
    id: number,
    note: string
    fullname: string
    phone_info: string
    image_info: any,
    is_working: boolean,
    created_date: string
}

type IQuery = TypedDriver & IQueryParams;


/*
* List of all app
*/
export function useMutateGetListDrivers() {
    return useMutation({
        mutationKey: ["list_drivers/fetch_list"],
        mutationFn: (object: IQuery) => axios.get<TypedDriver[]>(`/driver${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}


export function useSyncManual() {
    return useMutation({
        mutationKey: ['list_drivers/sync_manual'],
        mutationFn: () => axios.post(`/driver/sync`).then((response) => response.data).catch((error) => Promise.reject(error?.message || "Thất bại")),
    });
}