import { TypedTicket, useGetTickets } from 'queries/ticket.query';
import { Page, Badge, DataTable, Link, Text, Card, Tooltip } from '@shopify/polaris';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import dateandtime from 'date-and-time';
import helpers from 'helpers/index';
import Pagination from 'components/pagination';
import SimpleFilter from 'components/simpleFilter';
import { Helmet } from 'react-helmet-async';
import __, { ___ } from 'languages/index';
import { RefreshIcon } from '@shopify/polaris-icons';
import { useStartSyncAll } from 'queries/utils.query';
import { useNotification } from 'context/NotificationContext';
export default function Ticket() {
  const {
    addNotification
  } = useNotification();
  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 50,
    sort: 'id:desc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data,
    isFetching: loading
  } = useGetTickets(mainQuery);
  const [entities, setEntities] = useState<TypedTicket[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  useEffect(() => {
    if (data) {
      let {
        entities,
        totalItems
      } = data;
      setEntities(entities);
      setTotalItems(totalItems);
    }
  }, [data]);
  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    return mainQuery;
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);

  /**
   * I do not know ...
   * Bug: In React, do NOT remove this code
   */
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Ticket', '/ticket' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const {
    mutateAsync: startSyncAll,
    isPending: syncing,
    isSuccess: syncSuccess
  } = useStartSyncAll();
  const sync_all_ticket = useCallback(async () => {
    try {
      await startSyncAll('');
    } catch (e) {}
  }, []);
  useEffect(() => {
    if (syncSuccess) {
      addNotification('info', 'Đang sync trong nền ...');
    }
  }, [syncSuccess]);
  const styles: any = {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    width: '200px',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    overflow: 'auto'
  };
  return <>
      <Helmet>
        <title>Danh sách Vé</title>
      </Helmet>
      <Page title="Danh sách vé" fullWidth primaryAction={{
      content: 'Sync',
      icon: RefreshIcon,
      loading: syncing,
      onAction: () => {
        //
        sync_all_ticket();
      }
    }}>
        <Card padding="0">
          <SimpleFilter loading={loading} options={[]} onCallback={filterCallback} sortField={[{
          label: 'Tình trạng đặt hàng',
          field: 'status'
        }]} />
          <DataTable verticalAlign="middle" hideScrollIndicator={true} stickyHeader increasedTableDensity columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['Ngày tạo', 'Code', 'Khách hàng', 'Ngày đi', 'Hành trình', 'Từ', 'Đến', 'Số tiền', 'Ghi chú', 'Người đặt', 'Tình trạng']} rows={entities?.map(ticket => {
          const {
            id,
            code,
            customer,
            finalFare,
            journey,
            journeyId,
            trip_date,
            isPrgCreatedDate
          } = ticket;
          let trip_hour = dateandtime.format(new Date(trip_date), 'HH:mm', true);
          let trip_date_reformat = dateandtime.format(new Date(trip_date), 'DD-MM-YYYY', true);
          return [<>
                    <Text as="h3" variant="headingMd">
                      {dateandtime.format(new Date(isPrgCreatedDate), 'HH:mm', true)}
                    </Text>
                    <Text as="p" tone="subdued">
                      {dateandtime.format(new Date(isPrgCreatedDate), 'DD-MM-YYYY', true)}
                    </Text>
                  </>, <Link url={`/book-tickets?id=${journeyId}&d=${trip_date_reformat}&h=${trip_hour}#${id}`}>
                    <strong>{code}</strong>
                  </Link>, customer ? <>
                      <Link removeUnderline url={`/customer/view/${customer?.user_id}`}>
                        <Tooltip content={customer?.display_name}>
                          <p style={{
                  ...styles,
                  maxWidth: '100px',
                  fontWeight: 'bold'
                }}>{customer?.display_name}</p>
                        </Tooltip>
                      </Link>
                      {customer?.user_phonenumber && <Link url={`tel:${customer?.user_phonenumber}`}>
                          <Text as="p">{customer?.user_phonenumber}</Text>
                        </Link>}
                    </> : '--', trip_date ? <>
                      <Text as="h3" variant="headingMd">
                        {trip_hour}
                      </Text>
                      <Text as="p" tone="subdued">
                        {trip_date_reformat}
                      </Text>
                    </> : '--', <>
                    <Text as="p" fontWeight="bold">
                      {ticket?.journey?.name || '--'}
                    </Text>
                    {ticket?.trip?.driver && <>
                        <Text as="p" variant="bodyXs" tone="success">
                          {ticket?.trip?.driver?.fullname}: {ticket?.trip?.driver?.phone_info}
                        </Text>
                        {ticket?.trip?.driver2 && <Text as="p" variant="bodyXs" tone="success">
                            {ticket?.trip?.driver2?.fullname}: {ticket?.trip?.driver2?.phone_info}
                          </Text>}
                      </>}
                  </>, ticket?.pickupInfo ? <Tooltip content={String(ticket?.pickupInfo).split('||0|').shift().toString()}>
                      <p style={styles}>{String(ticket?.pickupInfo).split('||0|').shift().toString()}</p>
                    </Tooltip> : '--', ticket?.dropOffInfo ? <Tooltip content={String(ticket?.dropOffInfo).split('||0|').shift().toString()}>
                      <p style={styles}>{String(ticket?.dropOffInfo).split('||0|').shift().toString()}</p>
                    </Tooltip> : '--', helpers.formatNumber(ticket?.finalFare), <Tooltip content={ticket?.note}>
                    <p style={styles}>{ticket?.note}</p>
                  </Tooltip>, ticket?.createdUser || '--', <Badge tone={ticket?.status === 3 ? 'read-only' : 'success-strong'}>
                    {ticket?.status === 3 ? 'CANCEL' : ticket?.status === 1 ? 'OK' : 'PAID'}
                  </Badge>];
        }) || []} footerContent={___('Show page {display_page_number} of {total_record_number} results', {
          display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
        </Card>

        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
      <br />
      <br />
      <br />
      <br />
    </>;
}