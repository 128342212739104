import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SkeletonLoading from 'components/skeletonPageLoading';
import { Text, Card, DataTable, Link, Page, Badge, InlineStack, Avatar } from '@shopify/polaris';
import { ___ } from 'languages/index';
import __helpers from 'helpers/index';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import ListDriversFilter from './components/filter';
import { TypedDriver, useMutateGetListDrivers, useSyncManual } from 'queries/driver.query';
import { RefreshIcon } from '@shopify/polaris-icons';
import { useNotification } from 'context/NotificationContext';
const initialQuery = {
  query: '',
  page: 1,
  limit: 40,
  sort: 'created_date:desc'
};
export default function ListDriversView() {
  const rootSearchPath = window.location.search;

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = __helpers.ExtractUrl(rootSearchPath) || false;
  const {
    addNotification
  } = useNotification();
  const [listDrivers, setListDrivers] = useState<TypedDriver[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    mutateAsync: getEntities,
    isPending: loading
  } = useMutateGetListDrivers();
  const {
    mutateAsync: syncManual
  } = useSyncManual();
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const renderItem = (listDrivers: TypedDriver) => {
    if (__helpers.isEmpty(listDrivers)) return;
    const {
      id,
      created_date,
      fullname,
      phone_info,
      image_info,
      note,
      is_working
    } = listDrivers;
    let tone;
    switch (is_working) {
      case false:
        tone = 'info';
        break;
      default:
        tone = 'success';
        break;
    }
    return [<InlineStack key={id} gap="200" align="start" blockAlign="center">
        <Avatar source={image_info ? __helpers.getMediaLink(image_info) : null} name={fullname} initials={fullname?.charAt(0)} size="sm" />
        <Text fontWeight="bold" as="p">
          {__helpers.trimMiddleString(fullname)}{' '}
        </Text>
      </InlineStack>, <Link removeUnderline url={`tel:${phone_info}`}>
        <Text fontWeight="bold" as="p">
          {phone_info || ''}
        </Text>
      </Link>, <Badge tone={tone}>{is_working ? 'Đang làm việc' : 'Đã nghỉ'}</Badge>, <Text fontWeight="bold" as="p">
        {created_date ? dateandtime.format(new Date(created_date), 'DD-MM-YYYY HH:mm') : '-'}
      </Text>, <Text fontWeight="bold" as="p">
        {note || ''}
      </Text>];
  };
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const handleSyncManual = useCallback(async () => {
    await syncManual().then(() => {
      addNotification('info', 'Đồng bộ thành công');
    }).catch(error => {
      console.log('Sync Manual Error', error);
      addNotification('error', error);
    });
  }, [addNotification, syncManual]);
  const loadData = useCallback(async () => {
    try {
      let {
        body,
        totalItems
      } = await getEntities({
        sort: 'created_date:desc',
        limit: 20,
        ...mainQuery
      });
      setListDrivers(body);
      setTotalItems(totalItems);
    } catch (e) {}
  }, [mainQuery, getEntities]);
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [mainQuery]);
  useEffect(() => {
    let buildURLSearch = __helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Product', '/list-drivers' + buildURLSearch);
    }
  }, [mainQuery]);
  return <>
      <Helmet>
        <title>Danh sách tài xế</title>
      </Helmet>
      {listDrivers === null ? <SkeletonLoading fullWidth /> : <Page fullWidth title="Danh sách tài xế" primaryAction={{
      icon: RefreshIcon,
      content: 'Đồng bộ',
      onAction: () => handleSyncManual()
    }} compactTitle>
          <Card padding="0">
            <ListDriversFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[]} />
            <DataTable verticalAlign="middle" sortable={[false, false, false, false, false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['Họ và tên', 'Số điện thoại', 'Trạng thái', 'Ngày tham gia', 'Ghi chú']} rows={listDrivers?.map(renderItem)} hideScrollIndicator hasZebraStripingOnData increasedTableDensity stickyHeader footerContent={___('Show page {display_page_number} of {total_record_number} results', {
          display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
          </Card>

          <br />
          {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
        </Page>}
    </>;
}