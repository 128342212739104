import { BlockStack, Form, FormLayout, InlineStack, Modal, RadioButton, Select, Text, TextField } from '@shopify/polaris';
import { lengthLessThan, lengthMoreThan, notEmpty, useField, useForm } from '@shopify/react-form';
import QuickUploadImage from 'components/oneclick-upload-image';
import QuickSearchUser from 'components/quickSearchUser';
import dateandtime from 'date-and-time';
import helpers from 'helpers/index';
import { NotificationCategoryArray } from 'interface/notification.model';
import __ from 'languages/index';
import { ALLOWED_NOTIFICATION_DATA_KEY, SUGGEST_NOTIFICATION_DATA_KEY, useCreateNotification } from 'queries/notification.query';
import { useCallback, useEffect, useState } from 'react';

/**
 *   Create upload Modal for Notification
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default function NotificationNew({
  showModal,
  closeModal
}) {
  const {
    mutateAsync: createEntity,
    isPending: updating
  } = useCreateNotification();

  /* Radio button */
  const [userGroup, setUserGroup] = useState('everyone');
  const [hidePerson, setHidePerson] = useState(true);
  const handleRadioButtonChange = useCallback((_checked, newValue) => {
    setUserGroup(newValue);
    if (newValue !== 'everyone') {
      setHidePerson(false);
    } else {
      setHidePerson(true);
    }
  }, []);

  /* Choose person to send */
  const [person, setPerson] = useState('');
  const toggleUpdateActive = useCallback(() => {
    closeModal();
  }, []);

  /**
   * Select
   */

  const [selectedGroup, setSelectedGroup] = useState('/topics/all');
  const handleSelectChange = useCallback(value => setSelectedGroup(value), []);
  const notificationGroup = [{
    label: 'Tất cả mọi người',
    value: '/topics/all'
  }, {
    label: 'Tất cả vai trò User',
    value: '/topics/users'
  }, {
    label: 'Tất cả vai trò Customer',
    value: '/topics/customers'
  }];
  useEffect(() => {
    formReset();
  }, []);

  /* Create notification form */
  const {
    fields,
    submit,
    dirty,
    reset: formReset
  } = useForm({
    fields: {
      notification_image: useField<string>({
        value: undefined,
        validates: []
      }),
      notification_title: useField<string>({
        value: '',
        validates: [notEmpty('Title must be not empty'), lengthMoreThan(5, 'Title must be more than 5 characters'), lengthLessThan(200, 'No more than 200 characters.')]
      }),
      notification_content: useField<string>({
        value: '',
        validates: [notEmpty('Content must be not empty'), lengthMoreThan(5, 'Content must be more than 5 characters')]
      }),
      notification_category: useField<string>({
        value: 'general',
        validates: []
      }),
      notification_data_screen: useField<string>({
        value: ALLOWED_NOTIFICATION_DATA_KEY[0],
        validates: []
      }),
      notification_data_param: useField<string>({
        value: '',
        validates: []
      }),
      notification_schedule_date: useField<string>({
        value: dateandtime.format(new Date(), 'YYYY-MM-DD'),
        validates: []
      }),
      notification_schedule_time: useField<string>({
        value: dateandtime.format(new Date(), 'HH:MM'),
        validates: []
      })
    },
    async onSubmit(values) {
      try {
        // create new
        await createEntity({
          notification_title: values.notification_title,
          notification_content: `${values.notification_content}`,
          notification_data: {
            screen: values.notification_data_screen,
            param: values.notification_data_param
          },
          notification_schedule: new Date(values.notification_schedule_date + ' ' + values.notification_schedule_time).getTime(),
          notification_user: userGroup !== 'everyone' ? person : null,
          notification_channel: userGroup === 'everyone' ? selectedGroup : null,
          notification_category: values.notification_category,
          notification_image: values.notification_image ? helpers.getMediaLink(values.notification_image) : undefined
        });
        formReset();
        closeModal();
      } catch (e: any) {
        if (typeof e.params !== 'undefined' && e.params.field) {
          fields[e.params.field]?.setError(e.message);
        }
      }
      return {
        status: 'success'
      };
    }
  });
  const renderNotificationParamField = useCallback(() => {
    switch (fields.notification_data_screen.value) {
      default:
        return <TextField label="Thông số" {...fields.notification_data_param} autoComplete="off" />;
    }
  }, [fields.notification_data_screen.value, fields.notification_data_param.value]);
  const Actual_page = <Modal open={showModal} onClose={toggleUpdateActive} title={updating ? 'KHÔNG đóng cửa sổ này hoặc làm mới trình duyệt của bạn!' : 'Tạo thông báo'} primaryAction={{
    content: 'Tạo mới',
    disabled: !dirty,
    loading: updating,
    onAction: submit
  }} secondaryActions={[{
    content: 'Đóng',
    disabled: updating,
    onAction: toggleUpdateActive
  }]}>
      <Modal.Section>
        <Form onSubmit={submit}>
          <FormLayout>
            <InlineStack gap="400" align="start" blockAlign="start">
              <QuickUploadImage height={100} width={100} placeholder={helpers.getMediaLink(fields.notification_image.value, 'https://placehold.co/60x60')} onSuccess={res => {
              fields.notification_image.onChange(res.media_url);
            }} onError={() => alert('Upload ảnh bị lỗi, vui lòng thử lại.')} />
              <div style={{
              width: '80%'
            }}>
                <BlockStack gap={'500'}>
                  <TextField label="Tiêu đề" {...fields.notification_title} autoFocus autoComplete="off" />
                  <TextField multiline={3} label="Nội dung" {...fields.notification_content} autoComplete="off" />
                </BlockStack>
              </div>
            </InlineStack>
            <Select options={NotificationCategoryArray.map(el => {
            return {
              label: __(`notification_category_${el}`),
              value: el
            };
          })} label="Loại thông báo" value={fields.notification_category.value} onChange={v => fields.notification_category.onChange(v)} />

            <FormLayout>
              <FormLayout.Group>
                <Select options={ALLOWED_NOTIFICATION_DATA_KEY.map(screen => {
                return {
                  label: `${SUGGEST_NOTIFICATION_DATA_KEY.includes(screen) ? '⭐ ' : ''}${__('MOBILE_' + screen)}`,
                  value: screen
                };
              })} label="Màn hình" value={fields.notification_data_screen.value} onChange={v => {
                fields.notification_data_screen.onChange(v);
                fields.notification_data_param.onChange(''); // Reset param when screen changes
              }} />
                {renderNotificationParamField()}
              </FormLayout.Group>
            </FormLayout>

            <Text as="span">Hẹn giờ gửi</Text>

            <FormLayout>
              <FormLayout.Group>
                <TextField label="Ngày gửi" placeholder="Năm-tháng-ngày" {...fields.notification_schedule_date} autoComplete="off" />
                <TextField label="Giờ gửi" {...fields.notification_schedule_time} autoComplete="off" />
              </FormLayout.Group>
            </FormLayout>

            <BlockStack gap="400">
              <RadioButton label="Gửi tới một nhóm người" checked={userGroup === 'everyone'} id="everyone" name="everyone" onChange={handleRadioButtonChange} />

              <Select label="" disabled={!hidePerson} options={notificationGroup} onChange={handleSelectChange} value={selectedGroup} />

              <RadioButton label="Gửi cho một người cụ thể" id="person" name="person" checked={userGroup === 'person'} onChange={handleRadioButtonChange} />

              <QuickSearchUser title="" user_role="customer,collaborator,agency,visitor" disabled={hidePerson} onClose={customerData => setPerson(String(customerData.user_id))} />
            </BlockStack>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>;
  return <>{Actual_page}</>;
}