import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SkeletonLoading from 'components/skeletonPageLoading';
import { Text, Card, DataTable, Page } from '@shopify/polaris';
import { ___ } from 'languages/index';
import __helpers from 'helpers/index';
import Pagination from 'components/pagination';
import JourneyFilter from './components/filter';
import { RefreshIcon } from '@shopify/polaris-icons';
import { useNotification } from 'context/NotificationContext';
import { TypedJourney, useMutateGetJourney, useSyncManual } from 'queries/journey.query';
export default function JourneyView() {
  const rootSearchPath = window.location.search;

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = __helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40
  };
  const {
    addNotification
  } = useNotification();
  const [journeyData, setJourneyData] = useState<TypedJourney[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    mutateAsync: getEntities,
    isPending: loading
  } = useMutateGetJourney();
  const {
    mutateAsync: syncManual
  } = useSyncManual();
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const renderItem = (journeyData: TypedJourney) => {
    if (__helpers.isEmpty(journeyData)) return;
    const {
      id,
      name,
      FromArea,
      ToArea
    } = journeyData;
    function splitText(text: string = '') {
      if (!text) return '';
      const split = text?.split('|');
      return split?.[split?.length - 1] || '';
    }
    return [<Text key={id} fontWeight="bold" as="p">
        {__helpers.trimMiddleString(name)}{' '}
      </Text>, <Text fontWeight="bold" as="p">
        {splitText(FromArea) || ''}
      </Text>, <Text fontWeight="bold" as="p">
        {splitText(ToArea) || ''}
      </Text>];
  };
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const handleSyncManual = useCallback(async () => {
    await syncManual().then(res => {
      addNotification('info', 'Đồng bộ thành công');
    }).catch(error => {
      console.log('Sync Manual Error', error);
      addNotification('error', error);
    });
  }, [addNotification, syncManual]);
  const loadData = useCallback(async () => {
    try {
      const params = {
        limit: 20,
        ...mainQuery
      };
      let {
        body,
        totalItems
      } = await getEntities({
        ...params
      });
      setJourneyData(body);
      setTotalItems(totalItems);
    } catch (e) {}
  }, [mainQuery, getEntities]);
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [mainQuery]);
  useEffect(() => {
    let buildURLSearch = __helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Journey', '/journey' + buildURLSearch);
    }
  }, [mainQuery]);
  return <>
      <Helmet>
        <title>Hành trình</title>
      </Helmet>
      {journeyData === null ? <SkeletonLoading fullWidth /> : <Page fullWidth title="Hành trình" primaryAction={{
      icon: RefreshIcon,
      content: 'Đồng bộ',
      onAction: () => handleSyncManual()
    }} compactTitle>
          <Card padding="0">
            <JourneyFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[]} />
            <DataTable verticalAlign="middle" sortable={[false, false, false, false, false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['Hành trình', 'Điểm đi', 'Điểm đến']} rows={journeyData?.map(renderItem)} hideScrollIndicator hasZebraStripingOnData increasedTableDensity stickyHeader footerContent={___('Show page {display_page_number} of {total_record_number} results', {
          display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
          </Card>

          <br />
          {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
        </Page>}
    </>;
}