import { useParams } from 'react-router-dom';
import Theme404 from '../../layout/404';
import VehicleView from './vehicle-view';

/**
 *   Create index file
 */

export default function VehicleIndex() {
  let {
    slug = 'list'
  } = useParams();
  let ActualPage: any;
  switch (slug) {
    case 'list':
      ActualPage = VehicleView;
      break;
    default:
      ActualPage = Theme404;
      break;
  }
  return <>{<ActualPage />}</>;
}