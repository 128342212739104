import { Badge, Card, DataTable, Link, Text } from '@shopify/polaris';
import helpers from 'helpers/index';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TypedCustomer } from 'interface/customer.model';
import { TypedTicket, useGetTickets, useStartSyncViaPhonenumber } from 'queries/ticket.query';
import dateandtime from 'date-and-time';
import SimpleFilter from 'components/simpleFilter';
import Pagination from 'components/pagination';
export default function CustomerOtherInformation({
  entity
}: {
  entity: TypedCustomer;
}) {
  /**
   * Get all campaign this customer belong to...
   */
  let {
    customer_id
  } = useParams();
  const initialQuery = {
    limit: 20,
    customer_id: customer_id,
    sort: 'trip.DepartureTimeTimestamp:desc',
    page: 1
  };
  const [mainQuery, setMainQuery] = useState(initialQuery);
  const {
    data,
    isLoading,
    refetch: getTicketAgain
  } = useGetTickets(mainQuery);
  const {
    mutate: syncTicketViaPhonenumber,
    isPending: syncingDataFromCustomer
  } = useStartSyncViaPhonenumber();
  const startSyncTicket = useCallback(async () => {
    //
    try {
      syncTicketViaPhonenumber(entity?.user_phonenumber);
    } catch (e) {}
  }, []);
  useEffect(() => {
    if (entity) {
      startSyncTicket();
    }
  }, [entity]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [entities, setEntities] = useState<TypedTicket[]>([]);
  useEffect(() => {
    if (data) {
      setEntities(data.entities);
      setTotalItems(data.totalItems);
    }
  }, [data]);
  useEffect(() => {
    getTicketAgain();
  }, [mainQuery]);

  /** sau khi sync xong thì call cho phát */
  useEffect(() => {
    if (syncingDataFromCustomer) getTicketAgain();
  }, [syncingDataFromCustomer]);
  function getStatusBadge(status: number) {
    switch (status) {
      case 0:
        return <Badge></Badge>;
      case 3:
        return <Badge tone={'warning'} progress={'partiallyComplete'}>
            Đã Hủy
          </Badge>;
      case 1:
        return <Badge tone={'success'} progress={'complete'}>
            OK
          </Badge>;
      case 2:
        return <Badge tone={'success'} progress={'complete'}>
            PAID
          </Badge>;
    }
  }
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  return <>
      <Text as="h3" variant="headingMd">
        Đơn hàng gần nhất của khách
      </Text>
      <br />

      {syncingDataFromCustomer && <p>Đang đồng bộ ...</p>}

      <Card padding="0">
        {entities?.length > 0 ? <>
            <SimpleFilter onCallback={filterCallback} loading={isLoading} options={[]}
        // options={[{ label: 'Chưa diễn ra', value: `gte:${new Date(new Date().setHours(0)).getTime()}`, field: 'trip.DepartureTimeTimestamp' }]}
        sortField={[{
          label: 'Ngày đi',
          field: 'trip.DepartureTimeTimestamp'
        }]} />
            <DataTable verticalAlign="middle" stickyHeader={false} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'numeric']} headings={['Code', 'Ngày đi', 'Hành trình', 'Điểm đi', 'Điểm đến', 'Ghi chú', 'Số tiền', 'Tình trạng', 'Ngày tạo']} rows={entities?.map(ticketData => {
          const {
            id,
            journeyId,
            finalFare,
            note,
            code,
            createdAt,
            status,
            journey,
            trip,
            pickupInfo,
            dropOffInfo,
            trip_date
          } = ticketData;
          let trip_hour = dateandtime.format(new Date(trip_date), 'HH:mm', true);
          let trip_date_reformat = dateandtime.format(new Date(trip_date), 'DD-MM-YYYY', true);
          return [<Link url={`/book-tickets?id=${journeyId}&d=${trip_date_reformat}&h=${trip_hour}#${id}`}>
                    <strong>{code}</strong>
                  </Link>, trip_date ? dateandtime.format(new Date(trip_date), 'HH:mm DD-MM-YYYY', true) : '--', <>
                    <Text as="p">{journey?.name || '--'}</Text>
                    {trip?.driver && <Text as="p" tone="success">
                        {trip?.driver?.fullname}: {trip?.driver?.phone_info}
                      </Text>}
                  </>, pickupInfo ? String(pickupInfo).split('||0|').shift().toString() : '--', dropOffInfo ? String(dropOffInfo).split('||0|').shift().toString() : '--', note || '--', helpers.formatNumber(Number(finalFare), ',') /** trước là bookingFinalPrice */, getStatusBadge(status), dateandtime.format(new Date(Number(createdAt)), 'HH:mm DD-MM-YYYY', true)];
        })} hideScrollIndicator hasZebraStripingOnData increasedTableDensity />
          </> : null}
      </Card>

      <br />
      {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery.limit)} activeCurrentPage={Number(mainQuery.page)} /> : null}
    </>;
}