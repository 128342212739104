import { Button, IndexFilters, IndexFiltersMode, IndexFiltersProps, InlineStack, Link, Text, useSetIndexFiltersMode } from '@shopify/polaris';
import helpers from 'helpers/index';
import __ from 'languages/index';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * @label string
 * @value string
 */
type TypedOptionFilter = {
  label: string;
  value: string | (() => void);
  field?: string;
};
type TypedSort = {
  label: string;
  field: string;
};

/**
 * simple filter module for many entity
 * @onCallback callback when some button is click
 * @loading boolean
 * @options TypedOptionFilter[]
 * @sortField TypedSort , default is createdDate
 */

type SimpleFilterProps = {
  onCallback: (output: any) => void;
  options: TypedOptionFilter[];
  loading: boolean;
  sortField: TypedSort[];
  defaultSort?: string; // Giá trị sắp xếp mặc định (nếu có)
};
export default function JourneyFilter({
  onCallback,
  loading,
  options,
  sortField,
  defaultSort
}: SimpleFilterProps) {
  let mainQuery: any = helpers.ExtractUrl(window.location.search) || false;
  const {
    sort,
    query
  } = mainQuery;
  const [sortSelected, setSortSelected] = useState<string[]>([helpers.colonToOldSort(defaultSort || sort)]);
  const [queryValue, setQueryValue] = useState<string>(query);
  const [selected, setSelected] = useState(0);
  const handleSelectedChange = useCallback((field: string, _value: string | (() => void)) => {
    if (typeof _value === 'function') {
      _value.call(this, null);
      setSelected(0); // reset nút active, nếu không cóa thì không thể nào mà nó hoạt động ý
    } else {
      onCallback({
        [field]: _value,
        page: 1
      });
    }
  }, []);
  const searchHandleCallback = useCallback((value: string) => {
    onCallback({
      query: value,
      page: 1
    });
  }, []);
  const itemStrings = useMemo(() => [...[{
    label: 'Tất cả',
    value: '',
    field: ''
  }], ...options], []);
  const changeTabFilterAction = (index: number) => {
    if (index === 0) {
      onCallback(false);
    } else {
      handleSelectedChange(itemStrings[index]?.field, itemStrings[index]?.value);
    }
  };
  useEffect(() => {
    if (!mainQuery) return;
    // Duyệt qua từng key trong object và kiểm tra trong mảng
    const index = itemStrings.findIndex(item => mainQuery.hasOwnProperty(item.field) && mainQuery[item.field] === item.value);
    setSelected(index < 0 ? 0 : index);
  }, [mainQuery, itemStrings]);

  /** current_option Tìm current_option trong danh sách index để active nút lên... */
  // useEffect(() => {
  //   if (current_option !== null || current_option !== '') {
  //     let index = itemStrings.findIndex((item) => item.value === current_option);
  //     setSelected(index < 0 ? 0 : index);
  //   }
  // }, [current_option, itemStrings]);

  const tabs: any = Array.isArray(itemStrings) && itemStrings?.map((item, index) => ({
    content: item.label,
    index,
    onAction: () => {
      changeTabFilterAction(index);
    },
    id: `${item.label}-${index}`,
    isLocked: index === 0,
    actions: []
  }));
  const {
    mode,
    setMode
  } = useSetIndexFiltersMode(IndexFiltersMode.Default); // default search field
  /** current_search_keyword */
  useEffect(() => {
    if (query && query !== '') {
      setMode(IndexFiltersMode.Filtering);
    }
  }, [query]);
  const handleFiltersQueryChange = useCallback((value: string) => {
    setQueryValue(value);
    onChangeCallback(value);
  }, []);
  const onChangeCallback = useMemo(() => helpers.debounce(_value => searchHandleCallback?.call(this, _value), 800), []);
  const handleQueryValueRemove = useCallback(() => {
    setQueryValue('');
    searchHandleCallback('');
  }, []);
  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);
  const [sortOptions, setSortOptions] = useState<IndexFiltersProps['sortOptions']>([
    // { label: 'Ngày tạo', value: 'created_date, asc', directionLabel: __('ascending') },
    // { label: 'Ngày tạo', value: 'created_date, desc', directionLabel: __('descending') },
  ]);
  useEffect(() => {
    let _s: any[] = sortOptions;
    if (sortField) {
      for (let s of sortField) {
        _s = [..._s, ...[{
          label: s.label || '_',
          value: s.field + `, asc`,
          directionLabel: __('ascending')
        }, {
          label: s.label || '_',
          value: s.field + `, desc`,
          directionLabel: __('descending')
        }]];
      }
      setSortOptions(_s);
    }
  }, [sortField]);
  useEffect(() => {
    if (!sortSelected) return;
    let sortString = '';
    if (Array.isArray(sortSelected)) {
      sortString = sortSelected.join('');
    } else {
      sortString = sortSelected;
    }
    onCallback({
      sort: helpers.oldSortToColon(sortString)
    });
  }, [sortSelected]);
  return <>
      <IndexFilters sortOptions={sortOptions} sortSelected={sortSelected} queryValue={queryValue} queryPlaceholder={__('Tìm kiếm theo hành trình')} onQueryChange={handleFiltersQueryChange} onQueryClear={handleFiltersClearAll} onSort={setSortSelected} loading={loading || false} cancelAction={{
      onAction: () => {},
      disabled: false,
      loading: false
    }} tabs={tabs} selected={selected} onSelect={setSelected} canCreateNewView={false} onCreateNewView={null} onClearAll={handleFiltersClearAll} mode={mode} setMode={setMode} filters={[]} />
      <div className="Polaris-Filters__FiltersWrapper Polaris-Filters__FiltersWrapperWithAddButton filterWrapper">
        <div className="Polaris-Filters__FiltersInner">
          <InlineStack blockAlign="center" align="start" wrap={false}>
            {!helpers.isEmpty(queryValue) ? <InlineStack gap="400">
                <span onClick={() => setMode('FILTERING' as any)} className="FilterPill clickable">
                  {`Số điện thoại: ${queryValue}`}
                </span>{' '}
                <Button tone="critical" variant="plain" size="micro" onClick={handleFiltersClearAll}>
                  Xóa hết
                </Button>
              </InlineStack> : <Link removeUnderline onClick={() => setMode('FILTERING' as any)}>
                {'   '}{' '}
                <Text as="span" variant="bodySm" tone="subdued">
                  Mở bộ lọc (hoặc bấm nút F)
                </Text>
              </Link>}
          </InlineStack>
        </div>
      </div>
    </>;
}