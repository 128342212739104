import { FormLayout, Modal, TextField, Text, Form, InlineGrid, InlineStack, Select, Layout } from '@shopify/polaris';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import helpers from 'helpers';
import diachinh from 'config/diachinh.json';
import { lengthLessThan, lengthMoreThan, useField, useForm } from '@shopify/react-form';
import { TypedUser } from 'interface/user.model';
import DateTimeInput from 'components/dateTimeInput';
import __ from 'languages/index';
import { useMutateCreateCustomer } from 'queries/customer.query';
import Capacity from 'components/Capacity';

/**
 *   Create upload Modal for Notification
 */

// eslint-disable-next-line import/no-anonymous-default-export

function CustomerNew({
  onClose,
  show,
  defaultNumber = '',
  defaultEmail = '',
  defaultName = ''
}: {
  onClose: (userData?: TypedUser) => void;
  show: boolean;
  defaultNumber?: string;
  defaultEmail?: string;
  defaultName?: string;
}) {
  const {
    mutateAsync: createEntity
  } = useMutateCreateCustomer();
  const history = useNavigate();
  const onCloseCallback = useCallback((args: any) => {
    onClose(args);
  }, []);

  /**
   * Save and close or save and moving to detail?
   */
  const saveAndClose = useRef(false);
  const useFields = {
    display_name: useField<string>({
      value: defaultName,
      validates: [lengthLessThan(50, 'Không được dài hơn 50 ký tự.'), lengthMoreThan(1, 'Tên phải dài hơn 1 ký tự.')]
    }),
    bio: useField<string>({
      value: '',
      validates: [lengthLessThan(250, 'No more than 250 characters.')]
    }),
    user_phonenumber: useField<string>({
      value: defaultNumber,
      validates: [inputValue => {
        if (inputValue && !helpers.isPhoneNumber(inputValue)) {
          return 'Số điện thoại không hợp lệ';
        }
      }]
    }),
    user_birthday: useField<any>({
      value: '',
      validates: []
    }),
    user_address: useField<string>({
      value: '',
      validates: [lengthLessThan(150, 'No more than 150 characters.')]
    }),
    user_address_city: useField<string>({
      value: '',
      validates: []
    }),
    user_address_district: useField<string>({
      value: '',
      validates: []
    }),
    user_address_ward: useField<string>({
      value: '',
      validates: []
    }),
    user_title: useField<string>({
      value: '',
      validates: []
    }),
    user_email: useField<string>({
      value: defaultEmail,
      validates: [inputValue => {
        if (inputValue) if (!helpers.isEmail(inputValue)) {
          return 'Your email is not valid!';
        }
      }]
    })
  };
  const {
    fields,
    submit,
    submitting,
    dirty,
    reset,
    submitErrors,
    makeClean
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        // create new

        let userData = await createEntity({
          display_name: values.display_name,
          bio: values.bio,
          user_phonenumber: values.user_phonenumber,
          user_email: values.user_email,
          user_address: values.user_address,
          user_address_city: values.user_address_city,
          user_address_district: values.user_address_district,
          user_address_ward: values.user_address_ward,
          user_birthday: values.user_birthday || null,
          user_title: values.user_title
        });
        if (saveAndClose.current === true) {
          await helpers.sleep(1000);
          onCloseCallback(userData);
        } else {
          await helpers.sleep(1000);
          history('/customer/view/' + userData?.user_id);
        }
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success'
      };
    }
  });
  useEffect(() => {
    reset();
  }, [show]);
  const customer_address_city_selectType = useFields.user_address_city;
  const customer_address_district_selectType = useFields.user_address_district;
  const customer_address_ward_selectType = useFields.user_address_ward;

  /**
   * Địa chính
   * Chọn tỉnh, thành phố ...
   */
  const [diachinhCity, setDiachinhCity] = useState<string | undefined>(undefined);
  const [diachinhDistrict, setDiachinhDistrict] = useState<string | undefined>(undefined);
  const [diachinhDistrictList, setDiachinhDistrictList] = useState(undefined);
  const [diachinhWards, setDiachinhWards] = useState<string | undefined>(undefined);
  const [diachinhWardsList, setDiachinhWardsList] = useState(undefined);
  const diachinhCityCallback = useCallback((_value: string) => {
    setDiachinhCity(_value);
    customer_address_city_selectType.onChange(_value);
  }, []);
  const [diachinhCityList, setDiachinhCityList] = useState(null);
  useEffect(() => {
    let citys = [{
      label: 'Chọn Tỉnh/thành phố',
      value: ''
    }];
    for (let _city in diachinh) {
      let city = diachinh[_city];
      // @ts-ignore
      citys.push({
        label: city.name,
        value: city.code
      });
    }
    setDiachinhCityList(citys.sort((a, b) => a.label.localeCompare(b.label)));
  }, []);

  /**
   * Địa chính
   * Chọn Quận Huyện
   */
  const diachinhDistrictCallback = useCallback((_value: string) => {
    setDiachinhDistrict(_value);
    customer_address_district_selectType.onChange(_value);
  }, []);
  useEffect(() => {
    if (!diachinhCity) {
      return;
    }

    // setDiachinhDistrict(undefined);
    setDiachinhDistrictList(undefined);
    // setDiachinhWards(undefined);
    setDiachinhWardsList(undefined);
    let quanhuyens = [{
      label: 'Chọn Quận/ Huyện',
      value: ''
    }];
    for (let quanhuyen in diachinh[diachinhCity]?.['quan-huyen']) {
      let quanhuyendata = diachinh[diachinhCity]?.['quan-huyen']?.[quanhuyen];
      quanhuyens.push({
        label: quanhuyendata.name_with_type,
        value: quanhuyendata.code
      });
    }
    setDiachinhDistrictList(quanhuyens.sort((a, b) => a.label.localeCompare(b.label)));
  }, [diachinhCity]);

  /**
   * Địa chính
   * Chọn xã Phường ...
   */
  const diachinhWardsCallback = useCallback((_value: string) => {
    setDiachinhWards(_value);
    customer_address_ward_selectType.onChange(_value);
  }, []);
  useEffect(() => {
    if (!diachinhCity || !diachinhDistrict) {
      setDiachinhWardsList(undefined);
      return;
    }
    if (typeof diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict] === 'undefined') {
      return;
    }
    let xathitrans = [{
      label: 'Chọn Xã/ Thị trấn',
      value: ''
    }];
    for (let xathitran in diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict]?.['xa-phuong']) {
      // @ts-ignore
      let quanhuyendata = diachinh[diachinhCity]?.['quan-huyen']?.[diachinhDistrict]?.['xa-phuong']?.[xathitran];
      // let city = diachinh[_city];
      // @ts-ignore
      xathitrans.push({
        label: quanhuyendata.name_with_type,
        value: quanhuyendata.code
      });
    }
    setDiachinhWardsList(xathitrans.sort((a, b) => a.label.localeCompare(b.label)));
  }, [diachinhDistrict, diachinhCity]);

  /* End form */

  useEffect(() => {
    reset();
  }, []);
  const submitMode = useCallback((_mode: string) => {
    if (_mode === 'saveandclose') {
      saveAndClose.current = true;
    } else {
      saveAndClose.current = false;
    }
    submit();
  }, []);
  return <Modal open={show} onClose={() => onCloseCallback(null)} title={'Thêm khách hàng mới'} primaryAction={{
    content: 'Tạo & đóng',
    disabled: !dirty,
    loading: submitting,
    onAction: () => {
      submitMode('saveandclose');
    }
  }} secondaryActions={[{
    content: 'Đóng',
    onAction: () => onCloseCallback(null)
  }, {
    content: 'Tạo & Xem',
    disabled: !dirty || submitting,
    onAction: () => {
      submitMode('any');
    }
  }]}>
      <Modal.Section>
        <Capacity current_user_can={['manage_customers', 'edit_customers']} onFail={<>Bạn không có quyền truy cập.</>}>
          <Form onSubmit={submit}>
            <FormLayout>
              <TextField label="Tên hiển thị" autoComplete="off" {...fields.display_name} connectedLeft={<Select value={useFields.user_title.value} options={[{
              value: '',
              label: 'Không set'
            }, {
              value: 'mr',
              label: 'Anh'
            }, {
              value: 'miss',
              label: 'Cô'
            }, {
              value: 'mrs',
              label: 'Chị'
            }, {
              value: 'madam',
              label: 'Quý bà'
            }, {
              value: 'sir',
              label: 'Quý ông'
            }]} onChange={e => useFields.user_title.onChange(e)} label={''} />} />

              <InlineGrid gap="400" columns={2}>
                <TextField label="Số điện thoại" autoComplete="off" {...fields.user_phonenumber} />

                <TextField label="Email" autoComplete="off" {...fields.user_email} />
              </InlineGrid>

              <DateTimeInput onDateChange={({
              day,
              month,
              year
            }) => {
              fields.user_birthday.onChange(`${year}-${month}-${day}`);
            }} label={'Sinh nhật'} initialDate={fields.user_birthday.defaultValue} />

              <TextField label="Số nhà, đường, thôn xóm" autoComplete="off" {...fields.user_address} />

              <FormLayout.Group condensed>
                <Select key={'ahjfkdgf'} label="Tỉnh/Thành phố" options={diachinhCityList} value={diachinhCity} onChange={diachinhCityCallback} />
                <Select key={'ahjfdsfsgkdgf'} label="Quận/ huyện" options={diachinhDistrictList} value={diachinhDistrict} onChange={diachinhDistrictCallback} />
                <Select key={'ahjfksdgsdgsw4dgf'} label="Xã/Thị trấn" options={diachinhWardsList} value={diachinhWards} onChange={diachinhWardsCallback} />
              </FormLayout.Group>

              <TextField label="Ghi chú" autoComplete="off" multiline={2} {...fields.bio} />
            </FormLayout>
          </Form>
          <br />
          <InlineStack gap={'500'}>
            <Text as="p" variant="bodyMd">
              Bạn sẽ thêm được các thông tin khác trong giao diện chỉnh sửa toàn diện
            </Text>
          </InlineStack>
        </Capacity>
      </Modal.Section>
    </Modal>;
}
export default memo(CustomerNew);