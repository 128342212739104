import Calendar from 'react-calendar';
import { LunarDate } from 'vietnamese-lunar-calendar';
import 'react-calendar/dist/Calendar.css';
export default function LunarDateComponent({
  value,
  onChange,
  minDate
}: {
  value: Date;
  onChange: (date: Date) => void;
  minDate?: Date;
}) {
  function tileContent({
    date,
    view
  }) {
    if (view === 'month') {
      const day = new Date(date).getDate();
      const month = new Date(date).getMonth() + 1;
      const year = new Date(date).getFullYear();
      const {
        date: dateTitle,
        month: monthTitle,
        holiday
      } = new LunarDate(year, month, day);
      return <small className="lunar_title">{dateTitle === 1 || holiday ? `${dateTitle}/${monthTitle}` : dateTitle}</small>;
    }
  }
  return <Calendar locale="vi-vn" minDate={minDate} tileContent={tileContent} value={value} onChange={onChange} />;
}