import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import { IQueryParams } from "queries";
import { TypedJourney } from "./journey.query";
import { TypedTicket } from "./ticket.query";

/** Cho client ... */
export interface TypedSeatConfig {
    id: number;
    name: string;
    lock_positions: string | null;
    available_seat: string; // EG: available_seat: '"1|1|1|3", "2|1|2|1", "3|1|2|3", "4|1|3|1", "5|1|3|2", "6|1|3|3"'
}

export interface Trip {
    trip_time: string;
    default_fare: string;
    discount: string;
    seat_config: TypedSeatConfig | null;
    held_seats: number;
    total_seats: number;
}

export interface TravelInfo {
    journeyID: string;
    Name: string;
    FromArea: string;
    ToArea: string;
    DepartureDate: string;
    seat_config: TypedSeatConfig;
    trips: Trip[];
}
/** end cho client  */


interface DriverInfo {
    id: number;
    note: string;
    fullname: string;
    phone_info: string;
    image_info: string | null;
    is_working: boolean;
    created_date: string;
}

export interface TypedTrip {
    id: number;
    BaseId: number;
    DepartureTime: string; // ISO string format
    DepartureTimeTimestamp: string; // Unix timestamp in string
    VehicleId: number | null;
    FirstDriverId: number;
    SecondDriverId: number | null;
    SeatTemplateId: number;
    TotalSeats: number;
    TotalBookedSeats: number;
    hash: string;
    lastUpdated: string; // ISO string format
    vehicle: any | null; // If vehicle structure is known, replace `any` with appropriate interface
    driver: DriverInfo;
    driver2: DriverInfo | null;
    journey: TypedJourney;

    "seat_template"?: {
        "id": number,
        "name": string,
        "lock_positions": string,
        "available_seat": string, //"\"1|1|1|3\""
    }

}

type IQuery = IQueryParams;


/*
* List of all app
*/
export function useMutateGetTrips() {
    return useMutation({
        mutationKey: ["trip/fetch_list"],
        mutationFn: (object: IQuery) => axios.get<TypedTrip[]>(`/trip${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}
export function useClientGetTrips(object: IQuery) {
    return useQuery({
        queryKey: ["trip/fetch_list_for_client"],
        queryFn: () => axios.get<TravelInfo>(`/trip/admin${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchInterval: 15000,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
    });
}


/** sync trước khi lấy về ... */
/** Date phải đúng định dạng YYYY-MM-DD kẻo lỗi */
export function useSyncTripManual() {
    return useMutation({
        mutationKey: ['trip/sync_manual'],
        mutationFn: (body: { date: string, journey_id: string }) => axios.post(`/trip/sync`, body).then((response) => response.data),
    });
}