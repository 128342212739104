import { LegacyCard, TextContainer, SkeletonDisplayText, Divider, InlineGrid, Bleed, Box, BlockStack, SkeletonTabs } from '@shopify/polaris';
import '../media/skeleton.scss';
const SkeletonGetJourney = () => {
  return <TextContainer>
      <SkeletonDisplayText size="small" maxWidth="100%" />
      <div className="mh-200">
        <SkeletonDisplayText size="small" maxWidth="100%" />
      </div>
      <Divider />
    </TextContainer>;
};
const SkeletonGetTrip = () => {
  return <InlineGrid columns={3} gap={'400'}>
      {Array.from({
      length: 11
    }).map((_, index) => <SkeletonDisplayText key={index} size="small" />)}
    </InlineGrid>;
};
const SkeletonGetTicket = () => {
  return <>
      <LegacyCard sectioned>
        <BlockStack gap="200">
          <SkeletonDisplayText size="small" maxWidth="100%" />
          <Bleed marginBlockEnd="400" marginInline="400">
            <Box background="bg-surface-secondary" padding="400">
              <BlockStack gap="200">
                <SkeletonDisplayText size="small" />
                <SkeletonDisplayText size="small" />
                <Divider />

                <InlineGrid gap={'200'} columns={['oneThird', 'twoThirds']}>
                  <BlockStack gap="200">
                    <SkeletonDisplayText size="small" />

                    <SkeletonDisplayText size="small" />

                    <SkeletonDisplayText size="small" />
                  </BlockStack>
                  <BlockStack gap="200">
                    <SkeletonDisplayText size="small" />

                    <SkeletonDisplayText size="small" />

                    <SkeletonDisplayText size="small" />
                  </BlockStack>
                </InlineGrid>
              </BlockStack>
            </Box>
          </Bleed>
        </BlockStack>
      </LegacyCard>
      <LegacyCard sectioned>
        <BlockStack gap="200">
          <SkeletonTabs />
          <InlineGrid columns={3} gap={'400'}>
            {Array.from({
            length: 6
          }).map((_, index) => <SkeletonDisplayText key={index} size="extraLarge" />)}
          </InlineGrid>
        </BlockStack>
      </LegacyCard>
    </>;
};
export { SkeletonGetJourney, SkeletonGetTrip, SkeletonGetTicket };