/** Tình trạng thanh toán */
export const PAYMENT_STATUS_CONST = ['order_payment_status_unpaid', 'order_payment_status_partialpaid', 'order_payment_status_paid', 'order_payment_status_refunding', 'order_payment_status_refunded'];
export const ORDER_PAYMENT_STATUS = {
  '0': 'order_payment_status_unpaid',
  '1': 'order_payment_status_partialpaid',
  '2': 'order_payment_status_paid',
  '3': 'order_payment_status_refunding',
  '4': 'order_payment_status_refunded'
};
export const enum ENUM_PAYMENT_TYPE {
  Bank = 'bank',
  Cash = 'cash',
  Cod = 'cod',
}
export const PAYMENT_TYPE_CONST = ['bank', 'cash', 'cod'];
export const USER_ACTIVITY_LIST = ['TOUCH', 'BANKING', 'BUYGOOD', 'SOCIAL', 'SUBCRIBE', 'WEBSITE', 'CHECKIN', 'READEMAIL', 'EMAILOUT', 'EMAILIN', 'SMSOUT', 'SMSIN', 'CALLOUT', 'CALLIN', 'LOGIN', 'LOGINAPP', 'LOGINWEB', 'CONTACTFORM', 'SHOPPINGCART', 'REDEEM'];
export const CALL_STATUS_KEY = {
  MISSED: 'MISSED',
  ANSWERED: 'ANSWERED',
  BUSY: 'BUSY',
  UNKNOWN: 'UNKNOWN',
  NO_ANSWER: 'NO ANSWER',
  FAILED: 'FAILED'
};
export const CALL_STATUS = {
  [CALL_STATUS_KEY.MISSED]: 'Bỏ lỡ',
  [CALL_STATUS_KEY.ANSWERED]: 'Trả lời',
  [CALL_STATUS_KEY.BUSY]: 'Bận',
  [CALL_STATUS_KEY.UNKNOWN]: 'Đang nghe',
  [CALL_STATUS_KEY.NO_ANSWER]: 'Không trả lời',
  [CALL_STATUS_KEY.FAILED]: 'Thất bại'
};
export const DIRECTION_KEY = {
  OUTBOUND: 'outbound',
  INBOUND: 'inbound'
};
export const TICKETS_STATUS = {
  COMPLETE: 1,
  PAID: 2,
  DRAFF: 3
};