import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import queryClient from 'queries';

export function useCreateAssistants() {
    return useMutation({
        mutationKey: ['ai/create_assistants'],
        mutationFn: () => axios.post<any>(`/ai/assistants`).then(response => response.data),
    });
}


export function useGetAIModels() {
    return useMutation({
        mutationKey: ['ai/models'],
        mutationFn: () => axios.get<{
            "id": string,
            "object": string,
            "created": number,
            "owned_by": string
        }[]>(`/ai/models`).then(response => response.data),
    });
}