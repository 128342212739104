import { compiledBlock as _compiledBlock } from "million/react";
const BookTicketsView_1 = _compiledBlock(_props => <slot>
      {_props.v0}

      {_props.v1}
      <br />
      <br />
      <br />
      <br />
      <br />

      {/* <QuickBookModal show={showQuickTiketPopup} journeyData={journeyData} tripData={tripData} onClose={(a) => closeBookModalCallback(a)} /> */}
      {_props.v2}
      {_props.v3}
    </slot>, {
  name: "BookTicketsView_1",
  portals: ["v0", "v1", "v2", "v3"]
});
const BookTicketsView = function BookTicketsView() {
  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const [journeyData, setJourneyData] = useState<TypedJourney[]>([]);
  const [tripData, setTripData] = useState<TravelInfo>();
  const initialQuery = {
    limit: 100,
    BaseId: StringQuery?.id || '9695674',
    // default
    DepartureDate: dateandtime.format(StringQuery?.d ? dateandtime.parse(StringQuery?.d, 'DD-MM-YYYY') : new Date(), 'YYYY-MM-DD')
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...StringQuery
  });

  /** Loading thì dùng isLoading / isPending ở đây */
  const {
    mutateAsync: getEntities,
    isPending: loadingJourney
  } = useMutateGetJourney();
  const {
    refetch: reloadTripData,
    isPending: loadingTrip,
    data: tripRawData
  } = useClientGetTrips(initialQuery);
  const textHeader = useMemo(() => {
    const journey = journeyData?.find(item => item?.id === +mainQuery?.id)?.name || '';
    const date = mainQuery?.d?.split('-').join('/') || '';
    return `${mainQuery?.h || ''} • ${date} • ${journey}`;
  }, [mainQuery, journeyData]);

  /**
   * Start get journey
   */
  const loadJourneyData = useCallback(async () => {
    try {
      const params = {
        limit: 1000,
        page: 1,
        query: ''
      };
      let {
        body
      } = await getEntities(params);
      setJourneyData(body);
    } catch (e) {
      console.log('Get journey error', e);
    }
  }, [getEntities]);
  useEffect(() => {
    loadJourneyData();
    // eslint-disable-next-line
  }, []);

  /**
   * End get journey
   */

  useEffect(() => {
    if (!tripRawData) return;
    let {
      body
    } = tripRawData;
    if (helpers.isArray(body.trips) && body.trips.length > 0) {
      const sortedTrips = body.trips.sort((a, b) => {
        const timeA = a.trip_time.split(':').map(Number);
        const timeB = b.trip_time.split(':').map(Number);
        return timeA[0] * 60 + timeA[1] - (timeB[0] * 60 + timeB[1]);
      });
      body.trips = sortedTrips;
    }
    setTripData(body);
  }, [tripRawData]);
  useEffect(() => {
    if (helpers.isEmpty(mainQuery?.id)) return;
    if (helpers.isEmpty(mainQuery?.d)) return;
    reloadTripData();
  }, [mainQuery?.d, mainQuery?.id]);

  /**
   * End get ticket
   */

  // useScrollToTop([mainQuery?.h]);

  const [currentSeatsHold, setCurrentSeatsHold] = useState<string[]>([]);
  const onSelectedSeatCallback = useCallback((seatsSelected: string[]) => {
    setCurrentSeatsHold(prevSeats => {
      if (JSON.stringify(prevSeats) !== JSON.stringify(seatsSelected)) {
        return seatsSelected;
      }
      return prevSeats;
    });
  }, []);
  return /*@million jsx-skip*/<BookTicketsView_1 v0={<Helmet>
        <title>Quản lý vé</title>
      </Helmet>} v1={<Page fullWidth title="Quản lý vé" compactTitle>
        <Layout>
          {/* Cột chọn thời gian */}
          <Layout.Section variant="oneThird">
            <JourneyList loading={loadingJourney} journeyData={journeyData}
        // loadingTrip={loadingTrip}
        tripData={tripData} setMainQuery={setMainQuery} />
          </Layout.Section>

          {/* Danh sách vé */}
          <Layout.Section>
            {mainQuery.h && mainQuery.h !== '' ? <Suspense key={mainQuery.h}>
                <TicketLists tripData={tripData} textHeader={textHeader} onSelectedSeatCallback={seats => onSelectedSeatCallback(seats)} />
              </Suspense> : <SkeletonGetTicket />}
          </Layout.Section>
        </Layout>
      </Page>} v2={<BottomBar currentSeatsHold={currentSeatsHold} />} v3={<BottomBarMovingTicket currentSeatsHold={currentSeatsHold} tripData={tripData} />} />;
};
import { block as _block } from "million/react";
import { Page, Layout } from '@shopify/polaris';
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import JourneyList from './components/journey-list';
import { TypedJourney, useMutateGetJourney } from 'queries/journey.query';
import helpers from 'helpers/index';
import { TravelInfo, useClientGetTrips } from 'queries/trip.query';
import dateandtime from 'date-and-time';
// import TicketLists from './components/info-ticket';
// import useScrollToTop from 'hooks/use-scroll-top';
import __helpers from 'helpers/index';
import 'media/css/bottomBar.scss';
import BottomBar from './bottomBar';
import { SkeletonGetTicket } from './components/skeleton-loading';
import BottomBarMovingTicket from './bottomBarForMoving';
import TicketLists from './components/info-ticket';
/**
 * jamviet.com
 * @NOTE loading không làm thế kia em ạ, loading lấy ra từ reactQuery
 * @returns
 */

export default BookTicketsView;