import { Page } from '@shopify/polaris';
import __, { ___ } from 'languages/index';
import { Helmet } from 'react-helmet-async';
import ForSaleAndUpper from './forSaleAndUpper';
import Capacity from 'components/Capacity';

/************************************************************ *
 * MAIN
 * Private route
 ************************************************************ */

export default function Dashboard() {
  return <>
      <Helmet prioritizeSeoTags>
        <title>Dashboard</title>
      </Helmet>

      <Page>
        <Capacity current_user_can={'view_general_analytics'} onFail={<div>{'Bạn không có quyền truy cập'}</div>}>
          <ForSaleAndUpper />
        </Capacity>
      </Page>

      <br />
    </>;
}