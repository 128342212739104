import { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SkeletonLoading from 'components/skeletonPageLoading';
import { Text, Card, DataTable, Link, Page, Badge, InlineStack, Button } from '@shopify/polaris';
import __, { ___ } from 'languages/index';
import __helpers from 'helpers/index';
import dateandtime from 'date-and-time';
import Pagination from 'components/pagination';
import { TypedPhoneCall, useGetPhonelogs, useMutateGetPhonelogs } from 'queries/phonelog.query';
import { CALL_STATUS, CALL_STATUS_KEY, DIRECTION_KEY } from 'constant/index';
import AudioPlayer from './components/audio-player';
import { PhoneInIcon, PhoneOutIcon } from '@shopify/polaris-icons';
import CustomerNew from 'entities/customer/customer.new';
import SimpleFilter from 'components/simpleFilter';
export default function PhonelogView() {
  const rootSearchPath = window.location.search;

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = __helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 40,
    sort: 'createdAt:desc',
    disposition: ''
  };
  const [phonelogData, setPhonelogData] = useState<TypedPhoneCall[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [idPlaying, setIdPlaying] = useState(false);
  const currentlyPlayingRef = useRef(null); // Lưu tham chiếu tới audio đang phát

  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data,
    isLoading: loading
  } = useGetPhonelogs({
    sort: 'createdAt:desc',
    limit: 20,
    ...mainQuery
  });
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const renderItem = (phonelogData: TypedPhoneCall) => {
    if (__helpers.isEmpty(phonelogData)) return;
    let {
      id,
      destination,
      extension,
      createdAt,
      duration,
      disposition,
      source,
      recordingfile,
      direction,
      destination_data,
      source_data
    } = phonelogData;

    // https://tongdai.xuanquynh.vn/GHIAM
    // /var/spool/asterisk/monitor/2025/01/20/rg-998-0968209417-20250120-165806-1737367086.1946.wav
    if (recordingfile) {
      recordingfile = String(recordingfile).replace('/var/spool/asterisk/monitor', 'https://tongdai.xuanquynh.vn/GHIAM');
    }
    const isInbound = direction === DIRECTION_KEY.INBOUND;
    let tone;
    switch (disposition) {
      case CALL_STATUS_KEY.MISSED:
      case CALL_STATUS_KEY.FAILED:
        tone = 'critical';
        break;
      case CALL_STATUS_KEY.BUSY:
        tone = 'warning';
        break;
      case CALL_STATUS_KEY.NO_ANSWER:
        tone = 'magic';
        break;
      case CALL_STATUS_KEY.UNKNOWN:
        tone = 'attention';
        break;
      default:
        tone = 'success';
        break;
    }
    return [<div style={{
      minWidth: '100px'
    }}>
        <InlineStack blockAlign="center" gap={'200'} as="div">
          <Text tone="subdued" as="span">
            {isInbound ? <PhoneInIcon width={20} height={20} /> : <PhoneOutIcon width={20} height={20} />}
          </Text>
          <Badge tone={tone}>{CALL_STATUS?.[disposition] || ''}</Badge>
        </InlineStack>
      </div>, <Text as="p">
        {source_data ? <div>
            <Link removeUnderline url={`/customer/view/${source_data.user_id}`}>
              <strong>{source_data.display_name || source_data.user_phonenumber}</strong>
            </Link>
          </div> : <Link onClick={() => {
        currentNumber.current = source;
        setShowNewCustomer(true);
      }} removeUnderline>
            Thêm vào danh bạ
          </Link>}
        <Text as="h4" variant="headingMd" tone="success">
          {/* <Link removeUnderline url={`tel:${source}`}> */}
          {source || '--'}
          {/* </Link> */}
        </Text>
      </Text>, <Text fontWeight="bold" as="p">
        {extension ? extension : <>
            <Badge></Badge>
            <Badge></Badge>
          </>}
      </Text>, <Text as="p">{createdAt ? dateandtime.format(new Date(createdAt), 'DD-MM-YYYY HH:mm') : '-'}</Text>, <AudioPlayer id={id} duration={duration} idPlaying={idPlaying} setIdPlaying={setIdPlaying} currentlyPlayingRef={currentlyPlayingRef} audioUrl={recordingfile || ''} />, <Text as="p">
        {destination_data ? <div>
            <Link removeUnderline url={`/customer/view/${destination_data.user_id}`}>
              <strong>{destination_data.display_name || destination_data.user_phonenumber}</strong>
            </Link>
          </div> : <Link onClick={() => {
        currentNumber.current = destination;
        setShowNewCustomer(true);
      }} removeUnderline>
            Thêm vào danh bạ
          </Link>}
        <Text as="h4" variant="headingMd" tone="success">
          {/* <Link removeUnderline url={`tel:${destination}`}> */}
          {destination || ''}
          {/* </Link> */}
        </Text>
      </Text>];
  };
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  useEffect(() => {
    try {
      let {
        body,
        totalItems
      } = data;
      setPhonelogData(body);
      setTotalItems(totalItems);
    } catch (e) {}
  }, [data]);
  useEffect(() => {
    getEntities();
  }, [mainQuery]);
  useEffect(() => {
    let buildURLSearch = __helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Product', '/phonelog' + buildURLSearch);
    }
  }, [mainQuery]);
  const [showNewCustomer, setShowNewCustomer] = useState<boolean>(false);
  const currentNumber = useRef(null);
  const closeNewCustomerModal = useCallback(() => {
    // load laij duwx lieeuj
    setShowNewCustomer(false);
    currentNumber.current = null;
  }, [currentNumber]);
  return <>
      <Helmet>
        <title>Nhật ký cuộc gọi</title>
      </Helmet>
      {phonelogData === null ? <SkeletonLoading fullWidth /> : <Page fullWidth title="Nhật ký cuộc gọi" compactTitle>
          <Card padding="0">
            <SimpleFilter loading={loading} onCallback={filterCallback} options={[{
          label: 'GỌI NHỠ',
          field: 'disposition',
          value: 'MISSED'
        }, {
          label: 'BẬN',
          field: 'disposition',
          value: 'BUSY'
        }, {
          label: 'KHÔNG TRẢ LỜI',
          field: 'disposition',
          value: 'NO ANSWER'
        }]} sortField={[]} />
            <DataTable verticalAlign="middle" sortable={[false, false, false, false, false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['', 'Gọi đi', 'Gọi đến', 'Thời gian', 'Ghi âm', 'Nguồn']} rows={phonelogData?.map(renderItem)} hideScrollIndicator hasZebraStripingOnData increasedTableDensity stickyHeader footerContent={___('Show page {display_page_number} of {total_record_number} results', {
          display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
          </Card>

          <br />
          {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
        </Page>}

      <CustomerNew onClose={closeNewCustomerModal} show={!!showNewCustomer} defaultNumber={currentNumber.current} />
    </>;
}