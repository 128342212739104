import { InlineStack, SkeletonDisplayText, Text } from '@shopify/polaris';
import CustomerBirthDayList from 'components/customer_birthday';
import UserBirthDayList from 'components/user_birthday';
// import { useSettings } from 'context/SettingsContext';
import helpers from 'helpers/index';
import { useGetGeneralAnalytics } from 'queries/analytics.query';
export default function ForSaleAndUpper() {
  const {
    data: generalAnalyticsData,
    isLoading: loadingGeneralAnalytics
  } = useGetGeneralAnalytics();

  // const { settings } = useSettings();

  const HomeAnalytics = () => {
    return <div className="home_analytics">
        <InlineStack gap="400" align="space-between" blockAlign="start">
          <div className="dd">
            <Text as="h2" variant="headingMd" fontWeight="bold">
              Hi there, mọi thứ hôm nay
            </Text>
            <div className="home_analytics_body">
              <div className="home_analytics_badget">
                {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                    <div className="num">{helpers.getPad(generalAnalyticsData?.newCustomerToday ?? 0)}</div>
                    <div className="des">Khách hàng mới</div>
                  </>}
              </div>
              <div className="home_analytics_badget">
                {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                    <div className="num">{helpers.getPad(generalAnalyticsData?.orderCountToday ?? 0)}</div>
                    <div className="des">Đơn hàng mới</div>
                  </>}
              </div>
              <div className="home_analytics_badget">
                {loadingGeneralAnalytics ? <SkeletonDisplayText size="small" /> : <>
                    <div className="num">{helpers.getPad(generalAnalyticsData?.orderCancelCountToday ?? 0)}</div>
                    <div className="des">Đơn hàng hủy</div>
                  </>}
              </div>
            </div>
          </div>
        </InlineStack>
      </div>;
  };
  return <>
      <HomeAnalytics />
      <CustomerBirthDayList />
      <UserBirthDayList limit={100} />
      <br />
      <br />
      <br />
    </>;
}