import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import queryClient, { IQueryParams } from 'queries';

interface PriceDetail {
    original_price: number;
    discount: number;
    extra_fee: number;
}

interface BookingInfo {
    journey_id: string,
    customer_name: string;
    customer_phone: string;
    pickup_info: string;
    drop_off_info: string;
    note: string;
    seat: string[];
    price_table: PriceDetail[];
    trip_date: string; // full giờ đi: 05:00 25-02-2025
    already_paid: boolean;
    send_notification_to_customer: boolean;
}



export interface TypedTicket {
    id?: string | bigint;
    tripId?: Number;
    journeyId?: Number;
    bookingCode?: string;
    code?: string;
    seatCode?: string;
    fromArea?: string;
    toArea?: string;
    pickupInfo?: string;
    dropOffInfo?: string;
    pickupDriverTransferId?: string;
    dropOffDriverTransferId?: string;
    dropOffTransferInfo?: string;
    note?: string;
    bookingFinalPrice?: number; /** có vẻ phải bỏa */
    fare?: number;
    finalFare?: number;
    discount?: number,
    surcharge?: number,
    customer_id?: string;
    createdUser?: string;
    isPrgCreatedDate?: string;
    isPrgUpdatedDate?: string;
    trip_date?: string; /** 2025-03-02T21:30:00.000Z */
    status?: number; // 1 là đặt, 2 là đã thanh toán, 3 là vé hủy
    createdAt?: number;
    createdBy?: bigint;
    author?: {
        user_id: string,
        display_name: string,
        user_phonenumber: string,
    }



    "journey"?: {
        "id": number,
        "name": string,
        "FromArea": string,
        "ToArea": string,
    },
    "customer"?: {
        "user_id": string,
        "display_name": string,
        "user_phonenumber": string,
    },
    "trip"?: {
        "id": number,
        "BaseId": number,
        "DepartureTime": string,
        "DepartureTimeTimestamp": string,
        "VehicleId": number,
        "FirstDriverId": number,
        "SecondDriverId": number,
        "SeatTemplateId": number,
        "TotalSeats": number,
        "TotalBookedSeats": number,
        "hash": string,
        "lastUpdated": string,
        "driver"?: {
            "id": number,
            "note": null,
            "fullname": string,
            "phone_info": string,
            "image_info": string,
            "is_working": boolean,
            "created_date": string,
        },
        "driver2"?: {
            "id": number,
            "note": null,
            "fullname": string,
            "phone_info": string,
            "image_info": string,
            "is_working": boolean,
            "created_date": string,
        },
        "vehicle"?: {
            "id": number,
            "note": string,
            "license_plate": string,
            "total_seats": number,
            "seat_template_info": string,
            "is_prg_created_date": string,
        }
    }

}
type IQuery = TypedTicket & IQueryParams;


/*
* List of all app
*/
export function useGetTickets(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["ticket/fetch_entity_list"],
        queryFn: () => axios.get<TypedTicket[]>(`/ticket/${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                entities: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}

export function useMutateGetTickets() {
    return useMutation({
        mutationKey: ["ticket/fetch_list_ticket"],
        mutationFn: (object: IQuery) => axios.get<TypedTicket[]>(`/ticket/${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}
export function useMutateUpdateTicket() {
    return useMutation({
        mutationKey: ["ticket/fetch_list_ticket"],
        mutationFn: ({ id, data }: { id: string | number | bigint, data: TypedTicket }) => axios.patch<TypedTicket>(`/ticket/${id}`, data).then(response => response.data),
        retry: 1,
    });
}


/** Admin book vé ... */
export function useMutateTicketBooking() {
    return useMutation({
        mutationKey: ['ticket/admin_booking'],
        mutationFn: (body: BookingInfo) => axios.post(`/ticket/book`, body).then((response) => response.data),
        onSuccess: () => {
            /** Load lại trip */
            queryClient.invalidateQueries({ queryKey: ['trip/fetch_list_for_client'] });
            queryClient.invalidateQueries({ queryKey: ['ticket/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['ticket/fetch_list_ticket'] });
            queryClient.refetchQueries({ queryKey: ['ticket/fetch_list_ticket'] });
            queryClient.refetchQueries({ queryKey: ['fetch_entity_list'] });
        }
    });
}

/** Admin move vé ... */
export function useMutateTicketMoving() {
    return useMutation({
        mutationKey: ['ticket/admin_moving'],
        mutationFn: (body: { ticket_id: string, seat: string, trip_date: string, trip_id: number }) => axios.patch(`/ticket/move`, body).then((response) => response.data),
        onSuccess: () => {
            /** Load lại trip */
            queryClient.invalidateQueries({ queryKey: ['trip/fetch_list_for_client'] });
            /** Load lại ticket */
            queryClient.invalidateQueries({ queryKey: ['ticket/fetch_entity_list'] });
        }
    });
}

/** Admin hủy vé ... */
export function useMutateTicketCancel() {
    return useMutation({
        mutationKey: ['ticket/admin_cancel'],
        mutationFn: (ticket_id: number) => axios.delete(`/ticket/cancel/${ticket_id}`).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['trip/fetch_list_for_client'] });
            queryClient.invalidateQueries({ queryKey: ['ticket/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['ticket/fetch_list_ticket'] });
            queryClient.refetchQueries({ queryKey: ['ticket/fetch_list_ticket'] });
            queryClient.refetchQueries({ queryKey: ['fetch_entity_list'] });

        }
    });
}



export function useMutateGetTicketDetail() {
    return useMutation({
        mutationKey: ["ticket/fetch_ticket_detail"],
        mutationFn: (id: string) => axios.get<TypedTicket>(`/ticket/${id}`).then(response => response.data),
        retry: 1,
    });
}


/** sync trước khi lấy về ... */
/** Date phải đúng định dạng HH:mm YYYY-MM-DD kẻo lỗi */
export function useSyncTicketManual() {
    return useMutation({
        mutationKey: ['ticket/sync_manual'],
        mutationFn: (body: { date: string, journey_id: string }) => axios.post(`/ticket/sync`, body).then((response) => response.data),
    });
}


/** sync thông qua một số điện thoại */
export function useStartSyncViaPhonenumber() {
    return useMutation({
        mutationKey: ['utils/sync_all'],
        mutationFn: (phonenumber: string = '') => axios.get<any>(`/utils/sync_via_phonenumber/${phonenumber}`).then(response => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['ticket/fetch_entity_list'] });
        }
    });
}