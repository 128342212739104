import React, { useEffect, useRef, useState } from 'react';
import { Badge, DataTable, InlineStack, Text } from '@shopify/polaris';
import AudioPlayer from 'entities/phonelog/components/audio-player';
import { TypedPhoneCall, useGetPhonelogs } from 'queries/phonelog.query';
import dateandtime from 'date-and-time';
import __helpers from 'helpers/index';
import { CALL_STATUS, CALL_STATUS_KEY, DIRECTION_KEY } from 'constant/index';
import { PhoneInIcon, PhoneOutIcon } from '@shopify/polaris-icons';
export default function CustomerPhonecall({
  phonenumber = ''
}) {
  const [phonelogData, setPhonelogData] = useState<TypedPhoneCall[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const {
    data,
    isLoading: loading
  } = useGetPhonelogs({
    sort: 'createdAt:desc',
    limit: 20,
    query: phonenumber
  });
  useEffect(() => {
    try {
      let {
        body,
        totalItems
      } = data;
      setPhonelogData(body);
      setTotalItems(totalItems);
    } catch (e) {}
  }, [data]);
  const [idPlaying, setIdPlaying] = useState(false);
  const currentlyPlayingRef = useRef(null); // Lưu tham chiếu tới audio đang phát

  const renderItem = (phonelogData: TypedPhoneCall) => {
    if (__helpers.isEmpty(phonelogData)) return;
    let {
      id,
      destination,
      extension,
      createdAt,
      duration,
      disposition,
      source,
      recordingfile,
      direction,
      destination_data,
      source_data
    } = phonelogData;

    // https://tongdai.xuanquynh.vn/GHIAM
    // /var/spool/asterisk/monitor/2025/01/20/rg-998-0968209417-20250120-165806-1737367086.1946.wav
    if (recordingfile) {
      recordingfile = String(recordingfile).replace('/var/spool/asterisk/monitor', 'https://tongdai.xuanquynh.vn/GHIAM');
    }
    const isInbound = direction === DIRECTION_KEY.INBOUND;
    let tone;
    switch (disposition) {
      case CALL_STATUS_KEY.MISSED:
      case CALL_STATUS_KEY.FAILED:
        tone = 'critical';
        break;
      case CALL_STATUS_KEY.BUSY:
        tone = 'warning';
        break;
      case CALL_STATUS_KEY.NO_ANSWER:
        tone = 'magic';
        break;
      case CALL_STATUS_KEY.UNKNOWN:
        tone = 'attention';
        break;
      default:
        tone = 'success';
        break;
    }
    return [<div style={{
      minWidth: '100px'
    }}>
        <InlineStack blockAlign="center" gap={'200'} as="div">
          <Text tone="subdued" as="span">
            {isInbound ? <PhoneInIcon width={20} height={20} /> : <PhoneOutIcon width={20} height={20} />}
          </Text>
          <Badge tone={tone}>{CALL_STATUS?.[disposition] || ''}</Badge>
        </InlineStack>
      </div>, <Text as="p">
        {source_data ? <div>
            <strong>{source_data.display_name || source_data.user_phonenumber}</strong>
          </div> : <>--</>}
        <Text as="h4" variant="headingMd" tone="success">
          {/* <Link removeUnderline url={`tel:${source}`}> */}
          {source || '--'}
          {/* </Link> */}
        </Text>
      </Text>, <Text fontWeight="bold" as="p">
        {extension ? extension : <>
            <Badge></Badge>
            <Badge></Badge>
          </>}
      </Text>, <Text as="p">{createdAt ? dateandtime.format(new Date(createdAt), 'DD-MM-YYYY HH:mm') : '-'}</Text>, <AudioPlayer id={id} duration={duration} idPlaying={idPlaying} setIdPlaying={setIdPlaying} currentlyPlayingRef={currentlyPlayingRef} audioUrl={recordingfile || ''} />, <Text as="p">
        {destination_data && <div>
            <strong>{destination_data.display_name || destination_data.user_phonenumber}</strong>
          </div>}
        <Text as="h4" variant="headingMd" tone="success">
          {destination || ''}
        </Text>
      </Text>];
  };
  return <>
      <DataTable verticalAlign="middle" sortable={[false, false, false, false, false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['', 'Gọi đi', 'Gọi đến', 'Thời gian', 'Ghi âm', 'Nguồn']} rows={phonelogData?.map(renderItem)} hideScrollIndicator hasZebraStripingOnData increasedTableDensity stickyHeader />
    </>;
}