import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SkeletonLoading from 'components/skeletonPageLoading';
import { Text, Card, DataTable, Page } from '@shopify/polaris';
import __helpers from 'helpers/index';
import Pagination from 'components/pagination';
import JourneyFilter from './components/filter';
import { RefreshIcon } from '@shopify/polaris-icons';
import { useNotification } from 'context/NotificationContext';
import { TypedVehicle, useMutateGetVehicle, useSyncManual } from 'queries/vehicle.query';
import { ___ } from 'languages/index';
const initialQuery = {
  query: '',
  page: 1,
  limit: 40
};
export default function VehicleView() {
  const rootSearchPath = window.location.search;

  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = __helpers.ExtractUrl(rootSearchPath) || false;
  const {
    addNotification
  } = useNotification();
  const [vehicleData, setVehicleData] = useState<TypedVehicle[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    mutateAsync: getEntities,
    isPending: loading
  } = useMutateGetVehicle();
  const {
    mutateAsync: syncManual
  } = useSyncManual();
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const renderItem = (vehicleData: TypedVehicle) => {
    if (__helpers.isEmpty(vehicleData)) return;
    const {
      id,
      note,
      license_plate,
      total_seats
    } = vehicleData;
    return [<Text key={id} fontWeight="bold" as="p">
        {__helpers.trimMiddleString(note)}
      </Text>, <Text fontWeight="bold" as="p">
        {total_seats || 0}
      </Text>, <Text fontWeight="bold" as="p">
        {__helpers.trimMiddleString(license_plate)}
      </Text>];
  };
  const filterCallback = useCallback((_value: any) => {
    if (_value === false) {
      return setMainQuery(initialQuery);
    } else {
      setMainQuery(oldValue => {
        return {
          ...oldValue,
          ..._value
        };
      });
    }
  }, []);
  const handleSyncManual = useCallback(async () => {
    await syncManual().then(res => {
      addNotification('info', 'Đồng bộ thành công');
    }).catch(error => {
      console.log('Sync Manual Error', error);
      addNotification('error', error);
    });
  }, [addNotification, syncManual]);
  const loadData = useCallback(async () => {
    try {
      const params = {
        limit: 20,
        ...mainQuery
      };
      let {
        body,
        totalItems
      } = await getEntities({
        ...params
      });
      setVehicleData(body);
      setTotalItems(totalItems);
    } catch (e) {}
  }, [mainQuery, getEntities]);
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [mainQuery]);
  useEffect(() => {
    let buildURLSearch = __helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Product', '/vehicle' + buildURLSearch);
    }
  }, [mainQuery]);
  return <>
      <Helmet>
        <title>Phương tiện</title>
      </Helmet>
      {vehicleData === null ? <SkeletonLoading fullWidth /> : <Page fullWidth title="Phương tiện" primaryAction={{
      icon: RefreshIcon,
      content: 'Đồng bộ',
      onAction: () => handleSyncManual()
    }} compactTitle>
          <Card padding="0">
            <JourneyFilter loading={loading} onCallback={filterCallback} options={[]} sortField={[]} />
            <DataTable verticalAlign="middle" sortable={[false, false, false, false, false, false, false, false]} defaultSortDirection="descending" initialSortColumnIndex={6} onSort={null} columnContentTypes={['text', 'text', 'text', 'text', 'text', 'text', 'text']} headings={['Loại xe', 'Số ghế ngồi', 'Biển số xe']} rows={vehicleData?.map(renderItem)} hideScrollIndicator hasZebraStripingOnData increasedTableDensity stickyHeader footerContent={___('Show page {display_page_number} of {total_record_number} results', {
          display_page_number: <Text as="strong">{mainQuery?.page || 1}</Text>,
          total_record_number: <Text as="strong">{totalItems}</Text>
        })} />
          </Card>

          <br />
          {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
        </Page>}
    </>;
}