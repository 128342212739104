import { compiledBlock as _compiledBlock } from "million/react";
const BottomBarMovingTicket_1 = _compiledBlock(_props => <div className={_props.v0}>
      <div className="bottom-bar-body">
        <div className="bottom-bar-header">
          <div className="left-section">
            <div>Di chuyển vé</div>
            <div>
              {_props.v1} | {_props.v2}
            </div>
          </div>

          <div className="section right-section">
            {_props.v3}
          </div>
        </div>
      </div>
    </div>, {
  name: "BottomBarMovingTicket_1",
  portals: ["v1", "v2", "v3"]
});
const BottomBarMovingTicket = function BottomBarMovingTicket({
  currentSeatsHold,
  tripData
}: {
  currentSeatsHold: string[];
  tripData: TravelInfo;
}) {
  const [currentCustomerData, setCurrentCustomerData] = useState<TypedCustomer>(null);
  const [currentTicket, setCurrentTicket] = useState<TypedTicket>(null);
  /** Data lấy từ URL ... */
  let searchObject = new URLSearchParams(window.location.search);
  let quickbook_journey_id = searchObject.get('id'),
    quickbook_date = searchObject.get('d'),
    quickbook_hour = searchObject.get('h');
  const [showQuickBook, setShowQuickBook] = useState(false);
  useEffect(() => {
    let previousTicketData = null;
    const interval = setInterval(() => {
      let ticket_moving_data = localStorage.getItem('ticket_moving_data');
      if (quickbook_journey_id && helpers.isJson(ticket_moving_data)) {
        const ticketData = JSON.parse(ticket_moving_data);
        if (JSON.stringify(ticketData) !== JSON.stringify(previousTicketData)) {
          setShowQuickBook(true);
          setCurrentCustomerData(ticketData?.customer);
          setCurrentTicket(ticketData);
          previousTicketData = ticketData;
        }
      } else {
        setShowQuickBook(false);
        setCurrentCustomerData(null);
        setCurrentTicket(null);
        previousTicketData = null;
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [quickbook_journey_id]);
  const clearMoving = useCallback(() => {
    localStorage.removeItem('ticket_moving_data');
    setShowQuickBook(false);
  }, []);
  return /*@million jsx-skip*/<BottomBarMovingTicket_1 v0={`bottom-bar ${showQuickBook === true ? '' : 'hidden'}`} v1={currentTicket?.journey?.name} v2={dateandtime.format(new Date(currentTicket?.trip_date), 'DD-MM-YYYY HH:mm', true)} v3={<Button variant="plain" icon={XIcon} onClick={() => {
    clearMoving();
  }}>
              Hủy
            </Button>} />;
};
import { block as _block } from "million/react";
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@shopify/polaris';
import { XIcon, MenuHorizontalIcon } from '@shopify/polaris-icons';
import helpers from 'helpers/index';
import { TypedCustomer } from 'interface/customer.model';
import { TypedTicket } from 'queries/ticket.query';
import dateandtime from 'date-and-time';
import { TravelInfo } from 'queries/trip.query';
export default BottomBarMovingTicket;