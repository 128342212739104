import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";



/** Sync chủ động toàn bộ chuyến: d = 2025-01-16 */
export function useStartSyncAll() {
    return useMutation({
        mutationKey: ['utils/sync_all'],
        mutationFn: (date: string = '') => axios.get<any>(`/utils/sync_all?date=${date}`).then(response => response.data),
    });
}
