import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import { IQueryParams } from "queries";


export interface TypedVehicle {
  id: number,
  note: string
  license_plate: string
  total_seats: number
  seat_template_info: string
  is_prg_created_date: string
}

type IQuery = TypedVehicle & IQueryParams;


/*
* List of all app
*/
export function useMutateGetVehicle() {
  return useMutation({
    mutationKey: ["vehicle/fetch_list"],
    mutationFn: (object: IQuery) => axios.get<TypedVehicle[]>(`/vehicle${helpers.buildEndUrl(object)}`).then(response => {
      let { data, headers } = response;
      return {
        body: data,
        totalItems: Number(headers['x-total-count'] || 0)
      }
    }),
    retry: 1,
  });
}


export function useSyncManual() {
  return useMutation({
    mutationKey: ['vehicle/sync_manual'],
    mutationFn: () => axios.post(`/vehicle/sync`).then((response) => response.data).catch((error) => Promise.reject(error?.message || "Thất bại")),
  });
}