import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { IQueryParams } from "queries";
import helpers from "helpers/index";
import { TypedUser } from "interface/user.model";
import queryClient from 'queries';

/**
*   Interface/model file auto generate for Setting Group
*   Interface for back-end
*   Model for front-end.
*   They are the same!
*/
export interface TypedPhoneLog {
    id: string,
    source: string,
    direction: string,
    destination: string,
    extension: string,
    disposition: string,
    recordingfile: string,
    duration: number,
    uuid: string,
    createdAt: string,

    "destination_data"?: {
        "user_id"?: number,
        "user_phonenumber"?: string,
        "display_name"?: string,
        "user_email"?: string
    }
    "source_data"?: {
        "user_id"?: number,
        "user_phonenumber"?: string,
        "display_name"?: string,
        "user_email"?: string
    }
}

export interface TypedPhoneCall extends Partial<TypedPhoneLog> {
    phonelog_id?: string,
    phonelog_number?: string,
    phonelog_source?: string,
    user_id?: string,
    createdAt?: string,
    user?: TypedUser
}

type IQuery = TypedPhoneCall & IQueryParams;


export function useGetPhonelogs(object: IQuery) {
    const EndURL = helpers.buildEndUrl(object);
    return useQuery({
        queryKey: ["phonelog/fetch_entities_list"],
        queryFn: () => axios.get<TypedPhoneCall[]>(`/phonelog${EndURL}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
        refetchOnWindowFocus: true,
        enabled: true,
    });
}


/*
* List of all app
*/
export function useMutateGetPhonelogs() {
    return useMutation({
        mutationKey: ["phonelog/fetch_entity_list"],
        mutationFn: (object: IQuery) => axios.get<TypedPhoneCall[]>(`/phonelog${helpers.buildEndUrl(object)}`).then(response => {
            let { data, headers } = response;
            return {
                body: data,
                totalItems: Number(headers['x-total-count'] || 0)
            }
        }),
        retry: 1,
    });
}


export function useUpdatePhonelog() {
    return useMutation({
        mutationKey: ['phonelog/update_entity'],
        mutationFn: ({ phonelog_id, ...rest }: TypedPhoneCall) => axios.patch<TypedPhoneCall>(`/phonelog/${phonelog_id}`, helpers.cleanEntity(rest)).then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['phonelog/fetch_entity_list'] });
            queryClient.invalidateQueries({ queryKey: ['phonelog/fetch_entity'] });
        }
    });
}




/*
* who is calling
*/
export function useMutateWhoisCalling() {
    return useMutation({
        mutationKey: ["phonelog/who_is_calling"],
        mutationFn: () => axios.get<TypedPhoneCall[]>(`/phonelog/who_is_calling`).then(response => response.data),
        retry: 1,
    });
}








