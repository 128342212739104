import { compiledBlock as _compiledBlock } from "million/react";
const BottomBar_1 = _compiledBlock(_props => <div className={_props.v0}>
      <div className="bottom-bar-body">
        <div className="bottom-bar-header">
          <div className="left-section hide-on-mobile">
            <div>Đang đặt vé cho</div>
          </div>

          <div className="section">
            <div className="customer_name">
              {_props.v1}
            </div>
            <div className="big_guy">{_props.v2}</div>
          </div>
          <div className="section hide-on-mobile">
            <div>Số chỗ</div>
            <div className="big_guy">{_props.v3}</div>
          </div>
          <div className="section right-section">
            {_props.v4}
          </div>
        </div>
      </div>
    </div>, {
  name: "BottomBar_1",
  portals: ["v1", "v2", "v3", "v4"]
});
const BottomBar = function BottomBar({
  currentSeatsHold
}: {
  currentSeatsHold: string[];
}) {
  /** không đọc từ local nữa... */
  /** Data lấy từ URL ... */
  let searchObject = new URLSearchParams(window.location.search);
  let quickbook_journey_id = searchObject.get('id');

  /** QUICK BOOK */
  /** QUICK BOOK */
  /** QUICK BOOK */
  /** QUICK BOOK */
  /** QUICK BOOK */
  /** QUICK BOOK */

  const [showQuickBook, setShowQuickBook] = useState(false);
  const [currentCustomerData, setCurrentCustomerData] = useState<TypedCustomer>(null);
  useEffect(() => {
    const interval = setInterval(() => {
      let quickbook_customer_data = localStorage.getItem('quickbook_customer_data');
      // có đang moving một vé không?
      let ticket_moving_data = localStorage.getItem('ticket_moving_data');
      if (quickbook_journey_id && helpers.isJson(quickbook_customer_data) && !ticket_moving_data) {
        setShowQuickBook(true);
        setCurrentCustomerData(JSON.parse(quickbook_customer_data));
      } else {
        setShowQuickBook(false);
        setCurrentCustomerData(null);
      }
    }, 1500);
    return () => clearInterval(interval);
  }, [quickbook_journey_id]);
  const clearQuickBook = useCallback(() => {
    localStorage.removeItem('quickbook_customer_data');
    setShowQuickBook(false);
  }, []);
  return /*@million jsx-skip*/<BottomBar_1 v0={`bottom-bar ${showQuickBook === true ? '' : 'hidden'}`} v1={<Button icon={MenuHorizontalIcon} variant="monochromePlain" url={`/customer/view/${currentCustomerData?.user_id}`}>
                {currentCustomerData?.display_name}
              </Button>} v2={currentCustomerData?.user_phonenumber} v3={helpers.getPad(currentSeatsHold.length)} v4={<Button variant="plain" icon={XIcon} onClick={() => {
    clearQuickBook();
  }}>
              Hủy
            </Button>} />;
};
import { block as _block } from "million/react";
import { Button } from '@shopify/polaris';
import __helpers from 'helpers/index';
import helpers from 'helpers/index';
import { TypedCustomer } from 'interface/customer.model';
import __ from 'languages/index';
import { useCallback, useEffect, useState } from 'react';
import { XIcon, MenuHorizontalIcon } from '@shopify/polaris-icons';
export default BottomBar;