/**
 * Config bắt đầu từ đây!
 * Quy định route với: Private, public, separate route ...
 */
import Ticket from 'entities/ticket';
import Banner from 'entities/banner';
import Dashboard from 'entities/dashboard';
import EditMyProfile from 'entities/user-profile/edit-my-profile';
import Profile from 'entities/user-profile/profile';
import ActiveAccount from '../layout/active.account';
import Login from '../layout/login';
import QuickLogin from '../layout/quick.login';
import RecoverPassword from '../layout/recover-password';
import Phonelog from 'entities/phonelog';
import Customer from 'entities/customer';
import IncomingWebhook from 'entities/incoming_webhook';
import OutgoingWebhook from 'entities/outgoing_webhook';
import Analytics from '../entities/analytics';
import Contactform from '../entities/contactform';
import Media from '../entities/media';
import MediaSetting from '../entities/media_setting';
import Notification from '../entities/notification';
import Posts from '../entities/posts';
import Settings from '../entities/settings/index';
import UserGroup from '../entities/user_group';
import UserGroupDetail from '../entities/user_group/user_group.view';
import Users from '../entities/users';
import ViewPage from '../entities/viewpage';
import AccountSetting from 'entities/account_setting';
import BackUp from 'entities/backup';
import EmailGeneralSetting from 'entities/email_setting/email_general_setting';
import EmailTemplate from 'entities/email_setting/email_template';
import Embed from 'entities/embed';
import KeyMapIndex from 'entities/keymap';
import Security from 'entities/security';
import Webmail from 'entities/webmail';
import ListDriversIndex from 'entities/list-drivers';
import JourneyIndex from 'entities/journey';
import VehicleIndex from 'entities/vehicle';
import BookTicketsIndex from 'entities/book-tickets';
import zaloZNSApp_callback from 'entities/zaloZNSApp_callback';

/**
 * Private, w/ <AppFrame />
 * Only authorization can access this route
 */

export const private_route = [{
  path: '/',
  Entity: Dashboard
}, {
  path: '/ticket',
  Entity: Ticket
}, {
  path: '/keymap',
  Entity: KeyMapIndex
}, {
  path: '/webmail',
  Entity: Webmail
}, {
  path: '/webmail/:slug',
  Entity: Webmail
}, {
  path: '/webmail/:slug/:webmail_id',
  Entity: Webmail
}, {
  path: '/email_setting',
  Entity: EmailGeneralSetting
}, {
  path: '/email_template',
  Entity: EmailTemplate
}, {
  path: '/analytics/:feature',
  Entity: Analytics
}, {
  path: '/analytics',
  Entity: Analytics
}, {
  path: '/notification',
  Entity: Notification
}, {
  path: '/notification/:slug/:notification_slug',
  Entity: Notification
}, {
  path: '/notification/:slug',
  Entity: Notification
}, {
  path: '/profile',
  Entity: Profile
}, {
  path: '/edit-my-profile',
  Entity: EditMyProfile
}, {
  path: '/settings',
  Entity: Settings
}, {
  path: '/account_setting',
  Entity: AccountSetting
}, {
  path: '/account_setting/:slug',
  Entity: AccountSetting
}, {
  path: '/post/:post_slug',
  Entity: ViewPage
}, {
  path: '/posts/:slug/:pages_slug',
  Entity: Posts
}, {
  path: '/posts/:slug',
  Entity: Posts
}, {
  path: '/posts',
  Entity: Posts
}, {
  path: '/media',
  Entity: Media
}, {
  path: '/media/:slug/:media_slug',
  Entity: Media
}, {
  path: '/media/:slug',
  Entity: Media
}, {
  path: '/media_setting',
  Entity: MediaSetting
}, {
  path: '/users/:slug/:users_slug',
  Entity: Users
}, {
  path: '/users/:slug',
  Entity: Users
}, {
  path: '/users',
  Entity: Users
}, {
  path: '/user_group',
  Entity: UserGroup
}, {
  path: '/user_group/:user_group_id',
  Entity: UserGroupDetail
}, {
  path: '/incoming_webhook',
  Entity: IncomingWebhook
}, {
  path: '/incoming_webhook/:slug/:incoming_webhook_slug',
  Entity: IncomingWebhook
}, {
  path: '/incoming_webhook/:slug',
  Entity: IncomingWebhook
}, {
  path: '/outgoing_webhook',
  Entity: OutgoingWebhook
}, {
  path: '/outgoing_webhook/:slug/:outgoing_webhook_slug',
  Entity: OutgoingWebhook
}, {
  path: '/outgoing_webhook/:slug',
  Entity: OutgoingWebhook
}, {
  path: '/customer',
  Entity: Customer
}, {
  path: '/customer/:slug/:customer_id',
  Entity: Customer
}, {
  path: '/customer/:slug',
  Entity: Customer
}, {
  path: '/contactform',
  Entity: Contactform
}, {
  path: '/contactform/:slug/:contactform_id',
  Entity: Contactform
}, {
  path: '/contactform/:slug',
  Entity: Contactform
}, {
  path: '/backup',
  Entity: BackUp
}, {
  path: '/embed',
  Entity: Embed
}, {
  path: '/security',
  Entity: Security
}, {
  path: '/banner/:slug/:banner_id',
  Entity: Banner
}, {
  path: '/banner/:slug',
  Entity: Banner
}, {
  path: '/banner',
  Entity: Banner
}, {
  path: '/phonelog',
  Entity: Phonelog
}, {
  path: '/list-drivers',
  Entity: ListDriversIndex
}, {
  path: '/vehicle',
  Entity: VehicleIndex
}, {
  path: '/journey',
  Entity: JourneyIndex
}, {
  path: '/book-tickets',
  Entity: BookTicketsIndex
}];

/**
 * Any one can access this URL
 */
export const public_route = [];

/**
 * Run first and wihout <AppFrame>,
 * It is like login page, register page
 */

export const separate_route = [{
  path: '/zaloZNSApp_callback',
  Entity: zaloZNSApp_callback
}, {
  path: '/login',
  Entity: Login
}, {
  path: '/active-account',
  Entity: ActiveAccount
}, {
  path: '/login/sso',
  Entity: Login
}, {
  path: '/quick-login/:token',
  Entity: QuickLogin
}, {
  path: '/recover_password',
  Entity: RecoverPassword
}];