import { Text, Modal, InlineStack, BlockStack, Grid, Box, Form, Checkbox, Divider, FormLayout, TextField, Listbox, SkeletonBodyText } from '@shopify/polaris';
import { useState, useCallback, useEffect, useMemo } from 'react';
import helpers from 'helpers/index';
import { useMutateGetTicketDetail, useMutateTicketBooking, useMutateTicketCancel, useMutateUpdateTicket } from 'queries/ticket.query';
import { XSmallIcon, ConfettiIcon } from '@shopify/polaris-icons';
import { useNotification } from 'context/NotificationContext';
import __helpers from 'helpers/index';
import { lengthLessThan, notEmpty, useField, useForm } from '@shopify/react-form';
import { useMutateGetUserAddressBooksForButtomBar } from 'queries/user_address_book';
import { TypedCustomer } from 'interface/customer.model';
import __ from 'languages/index';
import { TravelInfo, Trip } from 'queries/trip.query';
export default function ModalDetailTicket({
  id,
  currentSeatsHold,
  tripData,
  onClose
}: {
  id: string;
  currentSeatsHold: string[];
  tripData: TravelInfo;
  onClose: (reload: boolean) => void;
}) {
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: ticketCancel,
    isPending: cancelling,
    isSuccess
  } = useMutateTicketCancel();
  const {
    mutateAsync: updateTicket,
    isPending: updating,
    isSuccess: updateSuccess
  } = useMutateUpdateTicket();
  const {
    data: ticketData,
    reset: resetTicketData,
    mutateAsync: getTicketDetail
  } = useMutateGetTicketDetail();
  const {
    mutateAsync: bookAticket,
    data,
    isSuccess: createSuccesfully
  } = useMutateTicketBooking();
  const [newTicketMode, setNewTicketMode] = useState(false);

  /** không đọc từ local nữa... */
  /** Data lấy từ URL ... */
  let searchObject = new URLSearchParams(window.location.search);
  let quickbook_journey_id = searchObject.get('id'),
    quickbook_date = searchObject.get('d'),
    quickbook_time = searchObject.get('h');
  const loadCustomerFromLocal = useCallback(() => {
    try {
      let quickbook_customer_data = localStorage.getItem('quickbook_customer_data');
      let quickbook_customer_data_after_parse = JSON.parse(quickbook_customer_data);
      setCurrentCustomerData(quickbook_customer_data_after_parse);
    } catch (e) {}
  }, [currentSeatsHold]);
  useEffect(() => {
    loadCustomerFromLocal();
  }, [currentSeatsHold]);
  useEffect(() => {
    if (!id) {
      resetTicketData();
      setNewTicketMode(true);
    } else {
      getTicketDetail(String(id)).catch(() => {});
      setNewTicketMode(false);
    }
  }, [id]);
  const {
    mutateAsync: mutateGetUserAddressBook,
    data: userAddressBookData,
    isPending: loadingUserAddressBook,
    isError: loadingUserAddressError,
    reset: resetUserAddressBook
  } = useMutateGetUserAddressBooksForButtomBar();

  /** QUICK BOOK */
  /** QUICK BOOK */
  /** QUICK BOOK */
  /** QUICK BOOK */
  /** QUICK BOOK */
  /** QUICK BOOK */

  const [currentCustomerData, setCurrentCustomerData] = useState<TypedCustomer>(null);
  useEffect(() => {
    if (ticketData) {
      setCurrentCustomerData(ticketData?.customer);
    }
  }, [ticketData]);
  const [totalMustPay, setTotalMustPay] = useState(0);
  const useFields = {
    /** create new one */
    journey_id: useField<string>({
      value: quickbook_journey_id,
      validates: []
    }),
    trip_time: useField<string>({
      value: quickbook_time,
      validates: []
    }),
    trip_date: useField<string>({
      value: quickbook_date,
      validates: []
    }),
    /** Edit ... */
    id: useField<string>({
      value: `` + ticketData?.id,
      validates: []
    }),
    customer_name: useField<string>({
      value: currentCustomerData?.display_name ?? '',
      validates: []
    }),
    customer_phone: useField<string>({
      value: currentCustomerData?.user_phonenumber ?? '',
      validates: []
    }),
    pickup_info: useField<string>({
      value: ticketData?.pickupInfo?.split('|')?.[0] || '',
      validates: [notEmpty('Trường này không được để trống.')]
    }),
    drop_off_info: useField<string>({
      value: ticketData?.dropOffInfo?.split('|')?.[0] || '',
      validates: [notEmpty('Trường này không được để trống.')]
    }),
    note: useField<string>({
      value: ticketData?.note || '',
      validates: [lengthLessThan(1000, 'Ghi chú quá dài...')]
    }),
    price_table: useField<{
      original_price: number;
      discount: number;
      extra_fee: number;
    }[]>({
      value: [{
        original_price: 0,
        discount: 0,
        extra_fee: 0
      }],
      validates: []
    }),
    seat: useField<string[]>({
      value: currentSeatsHold,
      validates: []
    }),
    already_paid: useField<boolean>({
      value: ticketData?.status === 2,
      validates: []
    }),
    // read only
    send_notification_to_customer: useField<boolean>({
      value: false,
      validates: []
    }) // read only
  };

  /** set gía khi edit */
  useEffect(() => {
    if (!ticketData) return;
    let price_table = [{
      original_price: Number(ticketData.fare),
      discount: Number(ticketData.discount),
      extra_fee: ticketData.surcharge
    }];
    useFields.price_table.onChange(price_table);
  }, [ticketData]);

  /** set giá khi tạo mới */
  const [currentTripData, setCurrentTripData] = useState<Trip>(null);
  useEffect(() => {
    if (tripData && tripData.trips) {
      setCurrentTripData(tripData?.trips[0]);
    }
  }, [tripData]);
  const priceTable = useMemo(() => {
    if (!newTicketMode || !currentTripData) return useFields.price_table.value;
    let [remove, remove2, price = 0] = String(currentTripData.default_fare).split('|');
    let [remove3, remove4, discount = 0] = String(currentTripData.discount).split('|');
    let price_table = [];
    let a = 0;
    while (a < currentSeatsHold.length) {
      a++;
      price_table.push({
        original_price: Number(price),
        discount: discount,
        extra_fee: 0
      });
    }
    return price_table;
  }, [newTicketMode, currentSeatsHold, currentTripData]);
  useEffect(() => {
    if (newTicketMode) {
      useFields.price_table.onChange(priceTable);
    }
  }, [priceTable, newTicketMode]);

  /** tính tổng giá phải trả */
  useEffect(() => {
    let totalMustPay = 0;
    let allPriceTable = useFields.price_table.value;
    for (let priceTag of allPriceTable) {
      totalMustPay += priceTag.original_price + priceTag.extra_fee - priceTag.discount;
    }
    setTotalMustPay(totalMustPay);
  }, [useFields.price_table.value]);
  const {
    fields,
    submit,
    submitting,
    dirty,
    makeClean,
    reset: resetForm
  } = useForm({
    fields: useFields,
    async onSubmit(values) {
      try {
        if (!newTicketMode) {
          let objectCreateNewRecord = {
            customer_name: values.customer_name,
            customer_phone: values.customer_phone,
            pickup_info: values.pickup_info,
            drop_off_info: values.drop_off_info,
            note: values.note,
            price_table: values.price_table,
            // trip_time: values.trip_time,
            already_paid: values.already_paid,
            send_notification_to_customer: values.send_notification_to_customer
          };
          await updateTicket({
            id: values.id,
            data: objectCreateNewRecord
          });
        } else {
          await bookAticket({
            journey_id: values.journey_id,
            customer_name: values.customer_name,
            customer_phone: values.customer_phone,
            pickup_info: values.pickup_info,
            drop_off_info: values.drop_off_info,
            note: values.note,
            seat: values.seat,
            price_table: values.price_table,
            // trip_time: values.trip_time,
            trip_date: String(values.trip_time || '').trim() + ' ' + String(values.trip_date || '').trim(),
            already_paid: values.already_paid,
            send_notification_to_customer: values.send_notification_to_customer
          });
        }
      } catch (e: any) {
        if (e.params !== undefined) {
          if (e.params.field !== undefined) {
            useFields[e.params.field].setError(__('check_this_field_again'));
          }
        }
      }
      return {
        status: 'success',
        errors: []
      };
    }
  });

  /** reset lai form */
  useEffect(() => {
    makeClean();
    resetForm();
    resetUserAddressBook();
  }, []);
  useEffect(() => {
    if (!currentCustomerData) return;
    // if (!ticketData) return;
    mutateGetUserAddressBook({
      journey_id: String(ticketData?.journeyId || quickbook_journey_id),
      user_id: currentCustomerData?.user_id
    });
  }, [currentCustomerData, ticketData, quickbook_journey_id]);

  /** OLD */
  useEffect(() => {
    if (isSuccess) {
      addNotification('info', 'Hủy vé thành công!');
      onClose(true);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (updateSuccess) {
      addNotification('info', 'Cập nhật vé thành công!');
      onClose(true);
    }
  }, [updateSuccess]);
  const clearQuickBook = useCallback(() => {
    localStorage.removeItem('quickbook_customer_data');
  }, []);
  useEffect(() => {
    if (createSuccesfully) {
      // phải clear nó đi ...
      addNotification('info', __('created_successfully'));
      clearQuickBook();
      onClose(true);
    }
  }, [createSuccesfully]);
  const ticketCancelCallback = useCallback(async () => {
    // eslint-disable-next-line no-restricted-globals
    let result = confirm('Bạn có thực sự muốn hủy vé này?');
    try {
      if (result) await ticketCancel(Number(id));
    } catch (e) {}
  }, [id]);
  return <>
      <Modal size="large" open={true} title={newTicketMode ? `Đặt vé (${tripData?.Name || ''}) ${quickbook_date} chuyến ${quickbook_time}` : `Chi tiết vé #${ticketData?.code} - ${currentCustomerData?.display_name} - ${currentCustomerData?.user_phonenumber}`} onClose={() => onClose(false)} secondaryActions={[...(!newTicketMode ? [{
      content: 'Hủy vé',
      icon: XSmallIcon,
      disabled: cancelling,
      onAction: () => ticketCancelCallback()
    }] : [])]} primaryAction={{
      content: newTicketMode ? `Book vé` : `Cập nhật`,
      icon: ConfettiIcon,
      disabled: cancelling || !dirty || totalMustPay < 1,
      loading: submitting,
      onAction: submit
    }}>
        <Modal.Section>
          <Form onSubmit={submit}>
            <Grid columns={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 2
          }}>
              <Grid.Cell>
                <BlockStack gap={{
                xs: '200',
                sm: '400'
              }}>
                  <FormLayout.Group>
                    <TextField autoComplete="off" placeholder="Tên khách hàng" label="Khách hàng" {...useFields.customer_name} disabled={submitting} />
                    <TextField autoComplete="off" placeholder="Số điện thoại" label="Số điện thoại" {...useFields.customer_phone} disabled={submitting} />
                  </FormLayout.Group>

                  <Divider />
                  <TextField autoComplete="off" placeholder="Địa chỉ đón" label="Đón tại" {...useFields.pickup_info} disabled={submitting} />
                  {loadingUserAddressBook ? <SkeletonBodyText /> : helpers.isEmpty(userAddressBookData) ? <Text as="p" tone="subdued">
                      Chưa có thông tin lưu sẵn
                    </Text> : <Listbox accessibilityLabel="Lịch sử địa chỉ điểm đón" onSelect={v => useFields.pickup_info.onChange(v)}>
                      {userAddressBookData?.from?.map((el, index) => {
                    if (el.user_address) return <Listbox.Option key={index} value={el.user_address}>
                              {el.user_address}
                            </Listbox.Option>;else return null;
                  })}
                    </Listbox>}
                  <TextField autoComplete="off" placeholder="Địa chỉ xuống" label="Xuống tại" {...useFields.drop_off_info} disabled={submitting} />
                  {loadingUserAddressBook ? <SkeletonBodyText /> : helpers.isEmpty(userAddressBookData) ? <Text as="p" tone="subdued">
                      Chưa có thông tin lưu sẵn
                    </Text> : <Listbox accessibilityLabel="Lịch sử địa chỉ điểm đến" onSelect={v => useFields.drop_off_info.onChange(v)}>
                      {userAddressBookData?.to?.map((el, index) => {
                    if (el.user_address) return <Listbox.Option key={index + '_bottombar_optionkey'} value={el.user_address}>
                              {el.user_address}
                            </Listbox.Option>;else return null;
                  })}
                    </Listbox>}
                </BlockStack>
              </Grid.Cell>
              <Grid.Cell>
                <BlockStack gap={{
                xs: '200',
                sm: '400'
              }}>
                  <TextField autoComplete="off" multiline={2} label="Ghi chú" showCharacterCount maxLength={1000} {...useFields.note} disabled={submitting} />
                  <Text as="h3" variant="headingMd">
                    Giá vé
                  </Text>
                  {useFields.price_table.value.map((el, index) => {
                  return <FormLayout.Group condensed key={index}>
                        <TextField autoComplete="off" label={`Vé #${index + 1}`} value={__helpers.formatNumber(el.original_price)} onChange={a => {
                      const updatedPriceTable = [...useFields.price_table.value];
                      updatedPriceTable[index] = {
                        ...updatedPriceTable[index],
                        original_price: __helpers.parseNumeric(a)
                      };
                      useFields.price_table.onChange(updatedPriceTable);
                    }} disabled={submitting} />
                        <TextField autoComplete="off" label="Phụ thu" value={__helpers.formatNumber(el.extra_fee)} onChange={a => {
                      const updatedPriceTable = [...useFields.price_table.value];
                      updatedPriceTable[index] = {
                        ...updatedPriceTable[index],
                        extra_fee: __helpers.parseNumeric(a)
                      };
                      useFields.price_table.onChange(updatedPriceTable);
                    }} disabled={submitting} />
                        <TextField autoComplete="off" label="Giảm giá" value={__helpers.formatNumber(el.discount)} onChange={a => {
                      const updatedPriceTable = [...useFields.price_table.value];
                      updatedPriceTable[index] = {
                        ...updatedPriceTable[index],
                        discount: __helpers.parseNumeric(a)
                      };
                      useFields.price_table.onChange(updatedPriceTable);
                    }} disabled={submitting} />
                      </FormLayout.Group>;
                })}

                  <Divider />
                  <InlineStack align="space-between" blockAlign="center">
                    <Text as="span" fontWeight="bold">
                      Tổng phải thanh toán
                    </Text>
                    <Text as="h3" fontWeight="bold" variant="headingLg" tone="critical">
                      {__helpers.formatNumber(totalMustPay)} VND
                    </Text>
                  </InlineStack>
                  <Divider />

                  <Checkbox label={'Đã thanh toán?'} checked={useFields.already_paid.value} onChange={a => useFields.already_paid.onChange(a)} disabled={submitting} />
                  <Checkbox label={'Thông báo cho khách qua Zalo ZNS?'} checked={useFields.send_notification_to_customer.value} onChange={a => useFields.send_notification_to_customer.onChange(a)} disabled={submitting} />
                </BlockStack>
              </Grid.Cell>
            </Grid>
          </Form>
        </Modal.Section>
      </Modal>
    </>;
}