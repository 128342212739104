import { Badge, Button, Card, DataTable, Text } from '@shopify/polaris';
import { TypedUser_address_book, useDeleteUserAddressBooks, useGetUserAddressBooks } from 'queries/user_address_book';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PhoneIcon, DeleteIcon } from '@shopify/polaris-icons';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
const provinceData = require('config/provinceData.json');
export default function CustomerAddressBook({
  user_id
}) {
  const {
    data
  } = useGetUserAddressBooks({
    limit: 20,
    user_id: user_id,
    sort: 'createdAt:desc'
  });
  const [entities, setEntities] = useState<TypedUser_address_book[]>(null);
  useEffect(() => {
    if (!data) return;
    let {
      totalItems,
      body
    } = data;
    setEntities(body);
  }, [data]);

  // delete ...
  const {
    mutateAsync: deleteUserAddressBook
  } = useDeleteUserAddressBooks();
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDelete = useCallback(async id => {
    const confirmed = await showDeleteConfirm('Xóa bài đăng này', 'Bạn có chắc muốn xóa không? Hành động này không thể khôi phục.');
    if (confirmed) {
      // Xử lý hành động xóa
      deleteUserAddressBook(id);
    }
  }, []);
  return <>
      <Card padding={'0'}>
        <DataTable hideScrollIndicator stickyHeader verticalAlign="middle" columnContentTypes={['text', 'text', 'text']} headings={['-', 'Từ', 'Địa chỉ']} rows={entities?.map(element => {
        return [<Button variant="plain" icon={DeleteIcon} onClick={() => handleDelete(element.user_address_book_id)}></Button>, <Text fontWeight="bold" as="p">
                  {provinceData[element?.province_id]}
                </Text>, <Text as="p">{element.user_address}</Text>];
      }) || []} />
      </Card>
    </>;
}