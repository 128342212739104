import { compiledBlock as _compiledBlock } from "million/react";
const TicketLists_1 = _compiledBlock(_props => <div onClick={_props.v0}>
      {_props.v1}

      {_props.v2}

      <div className="show-on-mobile">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>, {
  name: "TicketLists_1",
  portals: ["v1", "v2"]
});
const TicketLists = function TicketLists({
  textHeader,
  tripData,
  onSelectedSeatCallback
}: TypedProps) {
  const {
    addNotification
  } = useNotification();
  const {
    mutateAsync: ticketMovingFn,
    isPending: ticketMoving,
    isSuccess: ticketMovingSuccess
  } = useMutateTicketMoving();
  const rootSearchPath = window.location.search;
  let urlAfterExtract: any = helpers.ExtractUrl(rootSearchPath) || {};
  let initialQuery = {
    d: '',
    h: '',
    id: ''
  };
  const mainQuery = {
    ...initialQuery,
    ...urlAfterExtract
  };
  const hashToHightlight = String(window.location.hash || '').replace('#', '');
  const [tripSelected, setTripSelected] = useState(null);
  const [seat_config, setSeat_config] = useState<TypedSeatConfig>(null);
  /** chọn ghế để đặt */
  const [selectedSeat, setSelectedSeat] = useState<any[]>([]);
  useEffect(() => {
    // tripData
    if (!tripData) return;
    let {
      seat_config,
      trips
    } = tripData;
    let tripisSelected = trips.find(el => el.trip_time === mainQuery.h);
    setTripSelected(tripisSelected);
    if (tripisSelected && tripisSelected.seat_config && tripisSelected?.seat_config?.available_seat) {
      seat_config = tripisSelected.seat_config;
    }
    setSeat_config(seat_config);
  }, [tripData, mainQuery.h]);

  // const [ticketData, setTicketData] = useState<TypedTicket[]>(null);

  /** lộn cái date lại ... */
  let dateParse = dateandtime.preparse(mainQuery.d, 'DD-MM-YYYY');
  let rotateDate = `${dateParse.Y}-${helpers.getPad(dateParse.M)}-${helpers.getPad(dateParse.D)}T${mainQuery?.h}:00Z`;
  const {
    refetch: refetchTickets,
    isPending: loadingTicket,
    data
  } = useGetTickets({
    limit: 100,
    journeyId: mainQuery?.id,
    trip_date: rotateDate,
    sort: 'createdAt:asc'
  });

  /** Khi giờ thay đổi thì load lại vé ... */
  useEffect(() => {
    refetchTickets();
  }, [mainQuery?.h]);
  const ticketData = useMemo<TypedTicket[] | null>(() => {
    if (!data) return null;
    return data.entities;
  }, [data]);

  /** reset dữ liệu vé trước khi load xong */
  useEffect(() => {
    if (loadingTicket) {
      setSelectedSeat([]); // Clear tất cả vé khi click vào vé khác
    }
  }, [loadingTicket]);

  /**
   * Start get ticket
   */

  /** Chọn vé để di chuyển */
  /** Chọn vé để di chuyển */
  /** Chọn vé để di chuyển */
  /** Chọn vé để di chuyển */
  const [currentMovingTicket, setCurrentMovingTicket] = useState<TypedTicket>();
  const handMovingTicket = useCallback((id: any) => {
    setCurrentMovingTicket(prev => {
      const newTicket = prev?.id === id?.id ? null : id;
      if (newTicket) {
        localStorage.setItem('ticket_moving_data', JSON.stringify(newTicket));
      } else {
        localStorage.removeItem('ticket_moving_data');
      }
      return newTicket;
    });
  }, []);

  /** Lắng nghe khi bấm nút esc thì bỏ chức năng moving */
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setCurrentMovingTicket(null);
        localStorage.removeItem('ticket_moving_data');
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      try {
        let ticket_moving_data = localStorage.getItem('ticket_moving_data');
        if (ticket_moving_data) {
          const parsedData = JSON.parse(ticket_moving_data);
          if (JSON.stringify(parsedData) !== JSON.stringify(currentMovingTicket)) {
            setCurrentMovingTicket(parsedData);
          }
        } else {
          setCurrentMovingTicket(null);
        }
      } catch (e) {}
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  useEffect(() => {
    if (currentMovingTicket) localStorage.setItem('ticket_moving_data', JSON.stringify(currentMovingTicket));
  }, [currentMovingTicket]);

  /** Bấm nút di chuyển ... */
  const [seatLoading, setSeatLoading] = useState('');
  const moveTicketToHere = useCallback(async (seatCode: string) => {
    if (!currentMovingTicket) {
      return addNotification('info', 'Không có vé nào để di chuyển hoặc đã bị hủy.');
    }
    try {
      setSeatLoading(seatCode);
      await ticketMovingFn({
        ticket_id: `${currentMovingTicket.id}`,
        seat: seatCode,
        trip_date: rotateDate,
        // new date and time for moving ...
        trip_id: mainQuery.id
      });
      localStorage.removeItem('ticket_moving_data');
      setSeatLoading('');
    } catch (e) {}
  }, [currentMovingTicket, rotateDate, mainQuery]);

  /** Chức năng edit */
  /** Chức năng edit */
  /** Chức năng edit */
  /** Chức năng edit */
  const [showQuickModalEditCreateNewTicket, setShowQuickModalEditCreateNewTicket] = useState(false);
  const currentTicketForShowing = useRef(null);
  const handleViewDetailTicket = useCallback((id?: any) => {
    currentTicketForShowing.current = id || null;
    setShowQuickModalEditCreateNewTicket(true);
  }, []);
  const handleCloseViewDetailTicket = useCallback(async (reload: boolean) => {
    if (reload) {
      // load lại dữ liệu
      console.log('reload ticket data ...');
      await refetchTickets();
    }
    currentTicketForShowing.current = null;
    setShowQuickModalEditCreateNewTicket(false);
  }, []);

  /** Chọn chỗ để edit  */
  /** Chọn chỗ để edit  */
  /** Chọn chỗ để edit  */
  /** Chọn chỗ để edit  */
  /** Dựa theo code, vì có nhiều vé chung code */
  const [selectedTicketToView, setSelectedTicketToView] = useState<string>(null);
  const selecteTicketToViewCallback = useCallback((idx: string) => {
    if (!idx) return;
    setSelectedTicketToView(idx);
    setSelectedSeat([]); // Clear tất cả vé khi click vào vé khác
    setCurrentMovingTicket(null); // clear vé move khi click ra ngoài
  }, []);

  /** Chọn ghế để đặt ... */
  /** Chọn ghế để đặt ... */
  /** Chọn ghế để đặt ... */
  /** Chọn ghế để đặt ... */
  /** Chọn ghế để đặt ... */
  /** Chọn ghế để đặt ... */
  /** Lưu ý, trong khi di chuyển vé thì phụ thuộc vào vé cần di chuyển, nó sẽ đếm số lượng ghế, chỉ book theo số lượng */
  const selecteThisSeatCallback = useCallback((idx: string) => {
    if (!idx) return;
    if (currentMovingTicket) {
      setSelectedSeat([idx]);
    } else {
      setSelectedSeat(prevSelectedSeats => {
        if (prevSelectedSeats.includes(idx)) {
          return prevSelectedSeats.filter(seat => seat !== idx); // Bỏ chọn
        } else {
          return [...prevSelectedSeats, idx]; // Chọn ghế
        }
      });
    }
    setSelectedTicketToView(null); // Clear tất cả vé khi click vào ghế
  }, [currentMovingTicket]);
  useEffect(() => {
    if (!selectedSeat) return;
    onSelectedSeatCallback(selectedSeat);
  }, [selectedSeat]);

  /** TAB */
  const [tabSelected, setTabSelected] = useState(0);
  const handleTabChange = useCallback((selectedTabIndex: number) => setTabSelected(selectedTabIndex), []);
  const tabs = [{
    id: 'all-tickets',
    content: 'Toàn bộ vé'
  }, {
    id: 'all-journey',
    content: 'Toàn bộ hành trình'
  }];
  /** TAB END */
  return /*@million jsx-skip*/<TicketLists_1 v0={() => {
    setSelectedSeat([]); // Clear tất cả vé khi click ra ngoài
  }} v1={<BlockStack gap="400">
        <Card>
          <div>
            <BlockStack gap="200">
              <Text as="h2" variant="headingSm">
                {loadingTicket ? '::: Loading :::' : textHeader}
              </Text>
              <Box paddingBlockEnd="200"></Box>
            </BlockStack>
            <Bleed marginBlockEnd="400" marginInline="400">
              <Box background="bg-surface-secondary" padding="400">
                <BlockStack gap="200">
                  <InlineStack gap="400">
                    <DeliveryIcon width={20} height={20} />
                    <Text as="p" fontWeight="bold">
                      {seat_config?.name ? seat_config?.name + (tripSelected?.vehicle?.license_plate ?? '') : <>
                          <Badge></Badge>
                          <Badge></Badge>
                        </>}
                    </Text>
                  </InlineStack>
                  <InlineStack gap="400">
                    <PersonIcon width={20} height={20} />
                    <Text as="p" fontWeight="bold">
                      {`Tài xế: ${tripSelected?.driver?.fullname || '--'}`}
                    </Text>
                    <Text as="p" fontWeight="bold">
                      {`Số điện thoại: ${tripSelected?.driver?.phone_info || '--'}`}
                    </Text>
                  </InlineStack>
                  <InlineStack gap="400">
                    <PersonIcon width={20} height={20} />
                    <Text as="p" fontWeight="bold">
                      {`Phụ xe: ${tripSelected?.driver2?.fullname || '--'}`}
                    </Text>
                    <Text as="p" fontWeight="bold">
                      {`Số điện thoại: ${tripSelected?.driver2?.phone_info || '--'}`}
                    </Text>
                  </InlineStack>
                  {/* 
                   <Divider />
                   <InlineGrid columns={['oneThird', 'twoThirds']}>
                   <Box>
                    <Text as="p" fontWeight="bold">
                      {`${infoData?.trip?.TotalBookedSeats || 0}/${infoData?.trip?.TotalSeats || 0} vé đã đặt`}
                    </Text>
                    <Text as="p" fontWeight="semibold">
                      {`Lên xe -- • Đón -- • VP --`}
                    </Text>
                    <Text as="p" fontWeight="semibold">
                      TC đón -- • TC trả --
                    </Text>
                   </Box>
                   <Box>
                    <Text as="p" fontWeight="bold">
                      Tổng tiền -- • Tài xế thu --• Đã thu --
                    </Text>
                    <Text as="p" fontWeight="semibold">
                      Thanh toán --
                    </Text>
                    <Text as="p" fontWeight="semibold">
                      Đặt chỗ -- (VPHD : --)
                    </Text>
                   </Box>
                   </InlineGrid>
                   */}
                </BlockStack>
              </Box>
            </Bleed>
          </div>
        </Card>
        <div className="ticket_wrap_out">
          <Tabs tabs={tabs} selected={tabSelected} onSelect={handleTabChange}></Tabs>
          {tabSelected === 0 && <Card>
              <InlineGrid gap="400" columns={{
          xs: 1,
          sm: 1,
          md: 3
        }}>
                {seat_config?.available_seat?.split(',').map((_item, idx) => {
            let seatCode = String(_item || '').replaceAll('"', '').trim();
            const ticket = Array.isArray(ticketData) && ticketData && ticketData.find(el => el?.seatCode === seatCode);
            const isComplete = ticket?.status === TICKETS_STATUS?.COMPLETE || false;
            return <div key={idx + '_tiket_dj'} className={'ticket_element' + ' ' + (hashToHightlight === ticket?.id ? 'ticket_highlight' : '') + ' ' + (currentMovingTicket && currentMovingTicket.id === ticket?.id ? 'ticket_highlight' : '') + ' ' + (currentMovingTicket && !ticket ? 'ticket_moving_selectable' : '') + ' ' + (seatLoading !== '' && seatLoading === seatCode ? 'loading' : '')} style={{
              display: 'inherit',
              minHeight: '150px'
            }} onClick={e => {
              e.stopPropagation(); // Chặn sự kiện click lan lên `ticket_wrap_out`
              if (ticket) {
                selecteTicketToViewCallback(ticket?.code);
              } else {
                if (currentMovingTicket) {
                  moveTicketToHere(seatCode);
                } else {
                  selecteThisSeatCallback(seatCode);
                }
              }
            }}>
                      <Card background={selectedTicketToView === ticket?.code ? 'bg-fill-info' : selectedSeat.includes(seatCode) ? 'bg-fill-info' : isComplete ? 'bg-fill-critical-secondary' : 'bg-fill-secondary'}>
                        <BlockStack gap="400">
                          <InlineStack gap="400" align="space-between" blockAlign="start" wrap={false}>
                            <div>
                              <Text as="p" fontWeight="bold">
                                {idx + 1}
                              </Text>
                            </div>
                            <div>
                              <Text as="p" fontWeight="bold" alignment="end">
                                {ticket?.pickupInfo?.split('|')?.[0] && <Badge tone="success">{ticket?.pickupInfo?.split('|')?.[0] || ''}</Badge>}
                              </Text>
                              <Text as="p" fontWeight="bold" alignment="end">
                                {ticket?.dropOffInfo?.split('|')?.[0] && <Badge tone="success">{ticket?.dropOffInfo?.split('|')?.[0] || ''}</Badge>}
                              </Text>
                            </div>
                          </InlineStack>
                          {ticket ? <BlockStack gap="200">
                              <Grid gap={{
                      xs: '400'
                    }} columns={{
                      xs: 1,
                      sm: 1,
                      md: 2
                    }}>
                                <Grid.Cell columnSpan={{
                        xs: 6,
                        sm: 4,
                        md: 4,
                        lg: 8,
                        xl: 8
                      }}>
                                  <Text as="p">{ticket?.author?.display_name || ticket?.createdUser || ''}</Text>
                                  <Text as="p" fontWeight="semibold">
                                    {ticket?.customer?.display_name || ''}
                                  </Text>
                                  <Text as="p" fontWeight="semibold">
                                    {ticket?.customer?.user_phonenumber || ''}
                                  </Text>
                                  <Text as="p">{ticket?.note || ''}</Text>
                                </Grid.Cell>
                                <Grid.Cell columnSpan={{
                        xs: 6,
                        sm: 2,
                        md: 2,
                        lg: 4,
                        xl: 4
                      }}>
                                  <Text as="p" tone="critical" fontWeight="semibold">
                                    {ticket?.finalFare ? helpers.formatNumberWithCommas(ticket?.finalFare) + ' đ' : ''}
                                  </Text>
                                </Grid.Cell>
                              </Grid>
                              <Box paddingInlineEnd={'800'}>&nbsp;</Box>
                              <div className="toolchain">
                                <button onClick={e => {
                        e.stopPropagation();
                        handleViewDetailTicket(ticket?.id);
                      }}>
                                  U
                                </button>
                                <button onClick={e => {
                        e.stopPropagation();
                        handMovingTicket(ticket);
                      }}>
                                  M
                                </button>
                              </div>
                            </BlockStack> : <Box width="100%" minHeight="150px">
                              <InlineStack align="center" blockAlign="center">
                                <Button variant="plain" icon={selectedSeat.includes(seatCode) ? CheckIcon : PlusIcon}>
                                  {selectedSeat.includes(seatCode) ? 'Đã chọn' : currentMovingTicket ? 'Chuyển vé tới đây' : 'Chọn'}
                                </Button>
                              </InlineStack>
                              <Box paddingInlineEnd={'800'}>&nbsp;</Box>

                              <div className={`toolchain ${selectedSeat.includes(seatCode) ? '' : 'hidden'}`}>
                                {!currentMovingTicket && <button className="plain" onClick={e => {
                        e.stopPropagation();
                        handleViewDetailTicket();
                      }}>
                                    B
                                  </button>}
                              </div>
                            </Box>}
                        </BlockStack>
                      </Card>
                    </div>;
          })}
              </InlineGrid>
            </Card>}
          {tabSelected === 1 && <BlockStack gap="400">
              <DataTable hideScrollIndicator columnContentTypes={['text', 'text', 'text', 'text']} headings={['Khách hàng', 'Đón tại', 'Trả tại', 'Ghi chú']} rows={ticketData ? ticketData.map(item => [`${item?.customer?.display_name || '*'} - ${item?.customer?.user_phonenumber || '*'}`, item?.pickupInfo?.split('|')?.[0] || '-', item?.dropOffInfo?.split('|')?.[0] || '-', item?.note || '-']) : []} />

              <TextField label="Các điểm đón" value={ticketData?.map(item => {
          return item?.pickupInfo?.split('|')?.[0] || '-';
        }).join('\n')} onChange={() => {}} multiline={4} autoComplete="off" onFocus={e => {
          (e.target as HTMLTextAreaElement).select();
          helpers.copyToClipboard(ticketData?.map(item => {
            return item?.pickupInfo?.split('|')?.[0] || '-';
          }).join('\n'));
        }} />
              <TextField label="Các điểm trả" value={ticketData?.map(item => {
          return item?.dropOffInfo?.split('|')?.[0] || '-';
        }).join('\n')} onChange={() => {}} multiline={4} autoComplete="off" onFocus={e => {
          (e.target as HTMLTextAreaElement).select();
          helpers.copyToClipboard(ticketData?.map(item => {
            return item?.dropOffInfo?.split('|')?.[0] || '-';
          }).join('\n'));
        }} />
            </BlockStack>}
        </div>
      </BlockStack>} v2={showQuickModalEditCreateNewTicket && <ModalDetailTicket id={currentTicketForShowing.current} onClose={async (reload: boolean) => {
    await handleCloseViewDetailTicket(reload);
  }} tripData={tripData} currentSeatsHold={selectedSeat} />} />;
};
import { block as _block } from "million/react";
import { Card, Badge, BlockStack, Text, InlineGrid, Box, Bleed, InlineStack, Button, Tabs, DataTable, TextField, Grid } from '@shopify/polaris';
import { DeliveryIcon, PersonIcon, PlusIcon, CheckIcon } from '@shopify/polaris-icons';
import { TypedTicket, useGetTickets, useMutateTicketMoving } from 'queries/ticket.query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import helpers from 'helpers/index';
import { TICKETS_STATUS } from 'constant/index';
import ModalDetailTicket from './modal-detail-ticket';
import dateandtime from 'date-and-time';
import { TravelInfo, TypedSeatConfig } from 'queries/trip.query';
import '../media/ticket_list.scss';
import { useNotification } from 'context/NotificationContext';
interface TypedProps {
  textHeader: string;
  tripData: TravelInfo;
  onSelectedSeatCallback: (selectedSeat: any[]) => void;
}
export default TicketLists;