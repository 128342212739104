import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import helpers from "helpers/index";
import { IQueryParams } from "queries";


export interface TypedJourney {
  id: number,
  name: string
  FromArea: string
  ToArea: string
}

type IQuery = Partial<TypedJourney> & IQueryParams;


/*
* List of all app
*/
export function useMutateGetJourney() {
  return useMutation({
    mutationKey: ["journey/fetch_list"],
    mutationFn: (object: IQuery) => axios.get<TypedJourney[]>(`/journey${helpers.buildEndUrl(object)}`).then(response => {
      let { data, headers } = response;
      return {
        body: data,
        totalItems: Number(headers['x-total-count'] || 0)
      }
    }),
    retry: 1,
  });
}


export function useSyncManual() {
  return useMutation({
    mutationKey: ['journey/sync_manual'],
    mutationFn: () => axios.post(`/journey/sync`).then((response) => response.data).catch((error) => Promise.reject(error?.message || "Thất bại")),
  });
}