import { Card, BlockStack, Text, InlineGrid, Divider, OptionList, Button, ButtonGroup, InlineStack, Box } from '@shopify/polaris';
import { TypedJourney } from 'queries/journey.query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import helpers from 'helpers/index';
import dateandtime from 'date-and-time';
import { TravelInfo } from 'queries/trip.query';
import For from 'components/For';
import LunarDateComponent from './lunar-date';
import { SkeletonGetJourney, SkeletonGetTrip } from './skeleton-loading';
import 'media/css/journey-list.scss';
interface TypedProps {
  journeyData: TypedJourney[];
  tripData: TravelInfo;
  setMainQuery: (data: any) => void;
  loading: boolean;
}
export default function JourneyList({
  journeyData,
  tripData,
  setMainQuery,
  loading
}: TypedProps) {
  const rootSearchPath = window.location.search;
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const urlHash = window.location.hash;
  const journeyOptions = useMemo(() => {
    return journeyData?.map(item => {
      return {
        value: item?.id?.toString(),
        label: item?.name || ''
      };
    });
  }, [journeyData]);

  /**LINK -
   * Jamviet.com refactor, dropdown not work if journeyData has no data at starting state
   */
  useEffect(() => {
    setJourneyOption(journeyOptions);
  }, [journeyOptions]);

  /**
   * Jamviet.com refactor
   */

  const [selectedJourney, setSelectedJourney] = useState<string[]>([]);
  const [journeyOption, setJourneyOption] = useState(journeyOptions);
  const [selectTime, setSelectTime] = useState<string>(StringQuery?.h || ''); // phải set default kẻo bị lỗi phần highlight vé
  const [selectedDates, setSelectedDates] = useState(new Date());
  const updateSelection = useCallback((selected: string[]) => {
    setSelectedJourney(selected);
    setSelectTime('');
  }, [journeyOption, StringQuery]);
  const handleChangeDate = useCallback(date => {
    setSelectedDates(date);
    setSelectTime('');
  }, []);
  const reduceRequest = useCallback(params => {
    setMainQuery(mainQuery => ({
      ...mainQuery,
      ...params
    }));
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    const id = StringQuery?.id || journeyOptions?.[0]?.value || '';
    setSelectedJourney([id]);
  }, [journeyOptions]);
  useEffect(() => {
    // khi có hash để highlight một vé, thì không redirect
    if (tripData && !urlHash) {
      // đặt mặc định
      const time = tripData?.trips?.[0]?.trip_time || '';
      /** thay đổi lộ trình thì phải reset giờ ... */
      setSelectTime(time);
    }
  }, [tripData?.journeyID, tripData?.DepartureDate, urlHash]);
  useEffect(() => {
    if (!StringQuery?.d) return;
    const date = new Date(StringQuery?.d?.split('-')?.reverse()?.join('/')) || new Date();
    setSelectedDates(date);
  }, []);
  useEffect(() => {
    if (helpers.isEmpty(selectedJourney)) return;
    const params = {
      id: selectedJourney?.[0],
      d: dateandtime.format(new Date(selectedDates), 'DD-MM-YYYY'),
      h: selectTime
    };
    reduceRequestMemo(params);
    let buildURLSearch = helpers.buildEndUrl({
      ...params
    });
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'book', '/book-tickets' + buildURLSearch);
    }
  }, [selectedJourney, selectedDates, selectTime]);

  /**LINK -
   * Jamviet.com
   */

  return <>
      <Card>
        <BlockStack gap="400">
          {loading ? <SkeletonGetJourney /> : <>
              <OptionList title="" onChange={value => updateSelection(value)} options={journeyOption} selected={selectedJourney} />
              <ButtonGroup variant="segmented" fullWidth>
                <Button onClick={() => {
              setSelectedDates(new Date());
              setSelectTime('');
            }} variant={selectedDates?.toDateString() === new Date().toDateString() ? 'primary' : 'secondary'}>
                  Hôm nay
                </Button>
                <Button onClick={() => {
              setSelectedDates(new Date(new Date().setDate(new Date().getDate() + 1)));
              setSelectTime('');
            }} variant={selectedDates?.toDateString() === new Date(new Date().setDate(new Date().getDate() + 1)).toDateString() ? 'primary' : 'secondary'}>
                  Ngày mai
                </Button>
                <Button onClick={() => {
              setSelectedDates(new Date(new Date().setDate(new Date().getDate() + 2)));
              setSelectTime('');
            }} variant={selectedDates?.toDateString() === new Date(new Date().setDate(new Date().getDate() + 2)).toDateString() ? 'primary' : 'secondary'}>
                  Ngày kia
                </Button>
              </ButtonGroup>
              <LunarDateComponent value={selectedDates} onChange={handleChangeDate} />
            </>}

          <Divider />

          {loading ? <SkeletonGetTrip /> : <>
              <InlineGrid gap="400" columns={3}>
                <For fallback={<SkeletonGetTrip />} children={(timeSlot, idx) => {
              const isActive = selectTime === timeSlot?.trip_time;
              const totalBookedSeat = timeSlot?.held_seats || 0;
              const totalSeat = timeSlot?.total_seats || 5;
              const percentage = Math.floor(timeSlot?.held_seats / timeSlot?.total_seats * 100);
              let {
                driver,
                driver2
              } = timeSlot;
              return <div key={idx} onClick={() => setSelectTime(timeSlot?.trip_time)} className={`timeslot_element ${isActive ? 'active' : ''}`}>
                        <div className="timeslot_progress_bar">
                          <div className={`timeslot_progress_bar__inner ${percentage >= 90 ? '' : 'notice'}`} style={{
                    width: `${percentage}%`
                  }}></div>
                        </div>
                        <Box zIndex={'10'} position="relative">
                          <BlockStack gap="200">
                            <InlineStack gap="200" align="space-between" blockAlign="center">
                              <Text as="p" tone="critical" fontWeight="bold">
                                {timeSlot?.trip_time}
                              </Text>
                              <Text as="p">{`${totalBookedSeat}/${totalSeat}`}</Text>
                            </InlineStack>

                            <div>
                              <Text as="p" variant="bodySm">
                                {driver ? driver.fullname : '-'}
                              </Text>
                              <Text as="p" variant="bodySm">
                                {driver2 ? driver2.fullname : '-'}
                              </Text>
                            </div>
                          </BlockStack>
                        </Box>
                      </div>;
            }} each={tripData?.trips}></For>
              </InlineGrid>
            </>}
        </BlockStack>
      </Card>
      <br />
      <br />
    </>;
}